import React from 'react'
import { Modal, Button } from 'antd'

export default class FoodDescriptionModal extends React.Component {


    render() {
        if (!this.props.visible) return null;

        console.log("data->" + JSON.stringify(this.props.data));
        const { desc, enDesc } = this.props.data;

        return (
            <Modal
                title="菜品描述"
                visible={this.props.visible}
                onOk={this.handleOk}
                centered={true}
                onCancel={this.props.onCancel}
                footer={[
                    <Button key={"DescriptionModal"} type="primary" size="large" onClick={this.props.onCancel}>
                        确定
                    </Button>,
                ]} >
                <p style={{fontWeight:"bold", fontSize: "12px"}}>中文：</p>
                <p style={{ border: "1px solid #efdcdc", borderRadius: "5px", padding: "5px 10px 5px 10px" , minHeight: "100px"}}>{desc}</p>

                <p style={{fontWeight:"bold", fontSize: "12px"}}>英文：</p>
                <p style={{ border: "1px solid #efdcdc", borderRadius: "5px", padding: "10px" , minHeight: "100px"}}>{enDesc}</p>
            </Modal>
        )
    }


}

