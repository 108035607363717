import React from 'react'
import { Form, Select, Input, AutoComplete } from 'antd'

import '../../styles/addFood.css'
import UploadFile from './UploadPicture';
import Apis from '../../api';
import PageInfo from '../../pages/Paging'

const Option = Select.Option;
export default class FoodBasicInfoForm extends React.Component {
    static defaultProps = {
        hallData: [],
    }

    constructor(props) {
        super(props);
        this.foodTypesData = props.foodTypesData;
        this.state = {
            typeId: props.initData ? props.initData.typeId : null,
            hallId: props.initData ? this.getHallByTypeId(props.initData.typeId).id : null,
            loading: false
        }
    }



    handleHallChange = (key) => {

        this.props.form.setFieldsValue({
            "typeId": "",
            "hallId": key
        });

        this.setState({
            hallId: key,
            typeId: ""
        });
    }



    handleTypeChange = (key) => {
        this.setState({
            ...this.state,
            typeId: key
        });
    }

    getHallByTypeId = (typeId) => {
        var tmp;
        this.foodTypesData.forEach(item => {
            item.types.forEach(type => {
                if (type.id === typeId) {
                    tmp = item.hall;
                }
            })

        });
        return tmp;
    }

    getTypesByHallId = (hallId) => {
        var tmp = [];
        this.foodTypesData.forEach(item => {
            if (item.hall.id === hallId) {
                tmp = item.types;
            }
        });
        return tmp;
    }

    getTypeById = (typeId) => {
        var tmp = [];
        this.foodTypesData.forEach(item => {
            item.types.forEach(type => {
                if (type.id === typeId) {
                    tmp = type;
                }
            })
        });
        return tmp;
    }

    onSelect = (value) => {
        console.log("onSelect->" + (value));
        var data = JSON.parse(value);
        this.props.onSimpleFoodInfoReturn(data);
        this.setState({
            ...this.state,
            initData: {
                ...this.state.initData,
                ...data
            }
        }, () => {
            this.props.form.setFieldsValue({
                "name": data.name,
                "enName": data.enName,
                "description": data.description,
                "enDescription": data.enDescription,
                "picture": data.picture,

            });
        });


    }

    handleSearch = (value) => {
        value = value.replace(/\s+/g, "");
        console.log("auto complete value->" + value);
        if (value == null || value.length < 1) {
            this.setState({
                ...this.state,
                dataSource: []
            })
            return;
        }
        Apis.searchSimpleFoods(PageInfo.getPage(0, 10), value)
            .then(data => {
                if (data && data.data) {
                    data = data.forEach(item => {
                        delete item.picture
                    })
                    var foods = data.data;
                    const formatData = foods.map(food => {
                        return {
                            text: food.name,
                            value: JSON.stringify(food)
                        }
                    });
                    this.setState({
                        ...this.state,
                        dataSource: formatData
                    });
                }
            })
            .catch(error => {

            });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        this.foodTypesData = this.props.foodTypesData;
        const { loading, hallId, typeId } = this.state
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };
        const initData = this.props.initData

        const { dataSource } = this.state;
        return (
            // <Form
            //     layout={"horizontal"}
            // >
            <div style={{ display: this.props.visible ? 'inline' : 'none' }}>


                <Form.Item label="名称:" {...formItemLayout}>
                    {getFieldDecorator('name', {
                        initialValue: initData != null ? initData.name : "",
                        rules: [{
                            required: true,
                            message: '请输入菜品名称',
                        }/*,
                        {
                            min: 1, max: 20,
                            message: '请输入1-20个字符长度'
                        }*/
                        ],
                    })(
                        <AutoComplete
                            dataSource={dataSource}
                            onSelect={this.onSelect}
                            onSearch={this.handleSearch}
                            placeholder="输入菜品中文名称"
                        />
                    )}

                </Form.Item>


                {/* <Form.Item label="名称:" {...formItemLayout}>
                    {getFieldDecorator('name', {
                        initialValue: initData != null ? initData.name : "",
                        rules: [{
                            required: true,
                            message: '请输入菜品名称',
                        },
                        {
                            min: 1, max: 20,
                            message: '请输入1-20个字符长度'
                        }
                        ],
                    })(
                        <Input placeholder="输入菜品中文名称" />
                    )}

                </Form.Item> */}
                <Form.Item label="英文名称:" {...formItemLayout}>
                    {getFieldDecorator('enName', {
                        initialValue: initData != null ? initData.enName : "",
                        rules: [{
                            required: true,
                            message: '请输入菜品英文名称',
                        }/*,
                        {
                            min: 1, max: 50,
                            message: '请输入1-50个字符长度'
                        },*/
                        ],
                    })(
                        <Input placeholder="输入菜品英文名称" />
                    )}

                </Form.Item>
                <Form.Item label="所属餐厅:" {...formItemLayout}>
                    {getFieldDecorator('hallId', {
                        initialValue: hallId != null ? hallId : "",
                        rules: [{
                            required: true,
                            message: '请选择所属餐厅',
                        }
                        ],

                    })(
                        <Select onChange={this.handleHallChange}
                        >
                            {
                                this.props.hallData.map(element =>
                                    <Option key={element.id} value={element.id}>{element.name}</Option>
                                )
                            }
                        </Select>
                    )}

                </Form.Item>
                <Form.Item label="所属菜类:" {...formItemLayout}>
                    {getFieldDecorator('typeId', {
                        initialValue: typeId != null ? typeId : "",
                        rules: [{
                            required: true,
                            message: '请选择所属菜类',
                        }
                        ],

                    })(
                        <Select
                            onChange={this.handleTypeChange}
                            loading={loading}
                        >
                            {
                                this.getTypesByHallId(hallId).map(element =>
                                    <Option key={element.id} value={element.id}>{element.name}</Option>
                                )
                            }
                        </Select>
                    )}

                </Form.Item>
                <Form.Item label="图片:" {...formItemLayout}>
                    {getFieldDecorator('picture', {
                        initialValue: initData != null ? initData.picture : "",
                        rules: [
                        ],
                    })(
                        < UploadFile show={this.getTypeById(typeId).showPicture} />
                    )}
                    <span style={{fontsize:'10px', color:'red',display: 'block'}}>*图片分辨率为1920*1200，图片格式为.jpg</span>
                </Form.Item>

                <Form.Item label="排序号:" {...formItemLayout}>
                    {getFieldDecorator('order', {
                        initialValue: initData == null || initData.order == null ? this.props.index : initData.order,
                        rules: [{
                            required: true,
                            message: '请输入排序号',
                        }
                        ],
                    })(
                        <div >
                            <Input type="number" defaultValue={initData == null ?  this.props.index : initData.order} min={1} style={{ width: "100px" }} />
                            <label style={{ marginLeft: "20px", color: "gray" }}>（值越大，排序越靠前）</label></div>
                    )}
                </Form.Item>
            </div>
            // </Form>
        );
    }
}


