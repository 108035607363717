import React from 'react'

import { Table, message, Popconfirm, Divider, Modal, Pagination } from 'antd';

import MainToolbar from '../../components/MainToolbar'
import Apis from '../../api/'
import PageInfo from '../../pages/Paging'
import AddStoreModal from './AddStoreModal'
import MyEmpty from '../common/MyEmpty'

import '../../styles/pagination.css'
import StringUtils from '../../util/StringUtils'
import Status from '../../api/Status';

export default class StoreListContainer extends React.Component {
    state = {
        addModalVisible: false,
        isEditModal: false,
        editData: null,
        upLoading: false,

        deleteConfirmModalVisible: false,

        loading: false, //图标数据加载状态
        selectedRowKeys: [], // 已选中表单项id

        currentPage: 1,
        totalElements: 0

    };
    selectedRows = [];
    data = [];
    filters = {name: ""};

    columns = [{
        title: '名称',
        dataIndex: 'name',
    }, {
        title: '英文名称',
        dataIndex: 'enName',
    },
    {
        title: '操作',
        dataIndex: 'operation',
        render: (text, record) => (
            this.data.length >= 1
                ? (
                    <span>
                        <a href="javascript:;" onClick={() => this.onEditStoreClick(record)}>修改</a>
                        <Divider type="vertical" />
                        <Popconfirm title="确定删除该餐厅?" okText="删除" okType="danger" cancelText="取消" onConfirm={() =>
                            this.deleteStore(record)
                        }
                        >
                            <a href="javascript:;" style={{ color: "red" }}>删除</a>
                        </Popconfirm >
                    </span>
                ) : null
        ),
    }
    ];

    start = () => {
        // this.filters = {name: ""};
        this.setState({
            ...this.state,
            addModalVisible: false,
            loading: true
        });
        Apis.getHalls(PageInfo.getPage(0), this.filters)
            .then(data => {
                this.data = data.data;
                this.setState({
                    selectedRowKeys: [],
                    loading: false,
                    currentPage: 1,
                    totalElements: data.totalElements
                });
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    }

    loadPage = (pageIndex) => {
        this.setState({
            ...this.state,
            loading: true
        });
        Apis.getHalls(PageInfo.getPage(pageIndex), this.filters)
            .then(data => {
                this.data = data.data;
                this.setState({
                    loading: false,
                    currentPage: pageIndex + 1,
                    totalElements: data.totalElements
                });
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    }

    // onChange = (selectedRowKeys, selectedRows) => {
    //     console.log('selectedRowKeys changed: ', selectedRowKeys);
    //     console.log('selectedRows changed: ', selectedRows);
    // }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRows changed: ', selectedRows);
        this.selectedRows = selectedRows;
        this.setState({
            ...this.state,
            selectedRowKeys
        });
    }

    onAddClick = (e) => {
        this.setState({
            ...this.state,
            addModalVisible: true,
            isEditModal: false,
            editData: null
        });
    }

    onBatchDeleteClick = (e) => {
        const selectedData = this.selectedRows;
        if (selectedData == null || selectedData.length < 1) {
            message.info("请选择要删除的行");
        } else {
            Modal.confirm({
                title: '您确定要删除已选中的数据吗？',
                okText: "删除",
                cancelText: "取消",
                onOk: () => {
                    console.log("xxx1" + this.selectedData);
                    console.log("xxx2" + this.selectedRows);
                    this.deleteStoreInBatch(selectedData);
                    this.onEditStoreClick(selectedData);
                }
            });
        }
    }

    onSearchClick = (value, event) => {
        if (value ===this.filters.name) return;
        console.log("do search->" + value);
        const data = StringUtils.trim(value);
        this.filters = {
            ...this.filters,
            name: data
        }

        this.loadPage(0);
    }

    onEditStoreClick = (data) => {
        this.setState({
            ...this.state,
            addModalVisible: true,
            isEditModal: true,
            editData: data
        });
    }

    onPageChange = (page, pageSize) => {
        console.log("onPageChange page->" + page);
        //页码，需要减一
        this.loadPage(page - 1);
    }

    addStore = (data) => {
        Apis.addHall(data)
            .then(data => {
                this.start();
            })
            .catch(error => {
                console.log(error);
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    addModalVisible: false
                });
            })
    }

    editStore = (data) => {
        Apis.updateHall(data)
            .then(data => {
                this.start();
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    addModalVisible: false
                });
            })
    }

    deleteStore = (record) => {
        Apis.deleteHall(record)
            .then(data => {
                this.start();
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    }

    deleteStoreInBatch = (record) => {
        Apis.deleteHalls(record)
            .then(data => {
                this.start();
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    }

    onAddModalCancelClick = e => {
        this.setState({
            ...this.state,
            addModalVisible: false,
            isEditModal: false,
            editData: null
        })
    }

    componentDidMount() {
        console.log("componentDidMount")
        this.start();
    }


    render() {
        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <MainToolbar showFilter={false}
                    showBatchDelete={false}
                    addClick={this.onAddClick}
                    batchDeleteClick={this.onBatchDeleteClick}
                    searchClick={this.onSearchClick} />
                <Table
                locale={{ emptyText: <MyEmpty /> }}
                pagination={false} rowSelection={rowSelection} rowKey={'id'} columns={this.columns} dataSource={this.data} bordered loading={loading} />
                <Pagination current={this.state.currentPage} onChange={this.onPageChange} total={this.state.totalElements} className="right" />
                <AddStoreModal isEditModal={this.state.isEditModal}
                    initData={this.state.editData}
                    handleOk={this.state.isEditModal ? this.editStore : this.addStore}
                    handleCancel={this.onAddModalCancelClick} visible={this.state.addModalVisible}
                    upLoading={this.state.upLoading} />

                <Modal
                    title="确认"
                    okText="删除"
                    okType="danger"
                    cancelText="取消"
                    visible={this.state.deleteConfirmModalVisible}
                    onCancel={() => this.setState({
                        ...this.state,
                        deleteConfirmModalVisible: false
                    })}
                    onOk={this.handleConfirmDeleteOk}
                >
                    <p>确认删除已选中的记录吗？</p>
                </Modal>
            </div>

        );
    }
}
