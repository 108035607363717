import React from 'react'
import { Table, message, Divider, Popconfirm } from 'antd'

import Apis from '../../api'
import ToolBar from '../../components/MainToolbar'
import PageInfo from '../../pages/Paging'
import AddModal from './AddIntroductionModal'
import Status from '../../api/Status';
import MyEmpty from '../common/MyEmpty'

export default class IntroductionContainer extends React.Component {

    selectedHallId = null;
    selectedTypeId = '0';  //默认第一项
    state = {
        data: [],
        loading: false,
        // hallData: [],

        showAddModal: false,
        editItem: null,
    }

    types = [
        {
            id: "0",
            name: "促销"
        },
        {
            id: "1",
            name: "会员"
        },
        {
            id: "2",
            name: "宴会"
        },
        {
            id: "3",
            name: "酒店设施"
        },
    ]

    columns = [
        {
            title: "序号",
            dataIndex: "_index",
            render: (data, record, index) => { return index + 1 }

        },
        // {
        //     title: "餐厅",
        //     dataIndex: "hallName",
        // },
        {
            title: "类别",
            dataIndex: "typeName",
        },
        {
            title: "图片",
            dataIndex: "picture",
            render: (data, record, index) => {
                if (record.picture) {
                    return <img src={record.picture} alt="" style={{ width: "100px", height: "60px" }} />
                } else {
                    return <span style={{ color: "red" }}>未设置</span>
                }
            }
        },
        {
            title: "排序号",
            dataIndex: "order",
        },
        {
            title: '操作',
            width: "160px",
            dataIndex: 'operation',
            render: (text, record) => (
                <span>
                    <a href="javascript:;" onClick={() => this.onEditItemClick(record)}>修改</a>
                    <Divider type="vertical" />
                    <Popconfirm title="确定删除该条数据?" okText="删除" okType="danger" cancelText="取消" onConfirm={(e) => {
                        e.preventDefault();
                        this.deleteItem(record);
                    }
                    }
                    >
                        <a href="javascript:;" style={{ color: "red" }}>删除</a>
                    </Popconfirm >
                </span>
            ),
        }

    ];

    typeChanged = (typeId) => {
        this.selectedTypeId = typeId;
        this.refreshData();
    }

    hallChanged = (hallId) => {
        this.selectedHallId = hallId;
        this.refreshData();
    }

    refreshData = () => {
        this.setState({
            ...this.state,
            loading: true
        });
        Apis.getIntroductions(new PageInfo(), { "typeId": this.selectedTypeId })
            .then(data => {
                this.setState({
                    ...this.state,
                    loading: false,
                    data: data.data,
                })
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    loading: false,
                })
            })
    }

    addClick = () => {
        // if (this.state.hallData == null || this.state.hallData.length === 0) {
        //     message.error("请先添加餐厅");
        //     return;
        // }
        this.setState({
            ...this.state,
            showAddModal: true,
        })
    }

    onEditItemClick = (item) => {
        this.setState({
            ...this.state,
            showAddModal: true,
            initData: item,
        })
    }

    componentWillMount() {
        this.refreshData();
    }

    addItem = (data) => {
        if (this.state.initData != null) {
            this.updateItem(data);
            return;
        }
        console.log("additem->" + JSON.stringify(data));
        this.setState({
            ...this.state,
            upLoading: true
        });

        Apis.addIntroduction(data)
            .then(data => {
                this.setState({
                    ...this.state,
                    upLoading: false,
                    showAddModal: false
                });

                this.refreshData();

            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    upLoading: false,
                });

            });
    }

    updateItem = (data) => {
        console.log("updateItem->" + JSON.stringify(data));
        this.setState({
            ...this.state,
            upLoading: true
        });

        Apis.updateIntroduction(data)
            .then(data => {
                this.setState({
                    ...this.state,
                    upLoading: false,
                    initData: null,
                    showAddModal: false
                });

                this.refreshData();

            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    upLoading: false,
                });

            });
    }

    deleteItem = (item) => {
        this.setState({
            ...this.state,
            loading: true
        });

        Apis.deleteIntroduction(item)
            .then(data => {
                this.refreshData();
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    loading: false,
                });

            });

    }

    render() {
        return (
            <div>
                <ToolBar
                    selectTitle="请选择类别"
                    selectData={this.types}
                    showAllSelectItem={false}
                    showBatchDelete={false}
                    showSearch={false}
                    initSelectItem={this.types[0].id}
                    selectFilterChanged={this.typeChanged}
                    addClick={this.addClick} />
                <Table
                    locale={{ emptyText: <MyEmpty /> }}
                    dataSource={this.state.data}
                    columns={this.columns}
                    bordered
                    rowKey="id"
                    loading={this.state.loading}
                ></Table>
                <AddModal
                    visible={this.state.showAddModal}
                    // hallData={this.state.hallData}
                    typeData={this.types}
                    initData={this.state.initData}
                    onOk={this.addItem}
                    upLoading={this.state.upLoading}
                    onCancel={() => this.setState({
                        ...this.state,
                        showAddModal: false,
                        initData: null,
                    })}
                />

            </div>
        );
    }
}