/* eslint-disable no-use-before-define */
import React from 'react'

import LoginForm from '../../components/LoginForm'
import '../../styles/textstyles.css'
import '../../styles/common.css'
import Apis from '../../api'
import Status from '../../api/Status'
import Storage from '../../util/Storage'


export default class LoginContainer extends React.Component {
    state = {
        // userName: this.props.cookies.userName
        isError: false,
        // errorMsg: "",
        msg: ""
    };

    static protoTypes = {
    };

    static defaultProps = {
    }

    onLogin = (userName, psw) => {
        this.setState({
            isError: false
        });
        Apis.login(userName, psw)
            .then(data => {
                console.log(data);
                Storage.saveAuth(data.token);
                Storage.setUser(data.data);
                // localStorage.setItem("auth", data.token);
                const url = this.props.redirectTo;
                this.props.history.replace(url ? url : '/');
            }).catch(error => {
                this.setState({
                    isError: true,
                    // errorMsg: JSON.stringify(error),
                    msg: Status.getMessage(error.code)
                });
            })
    }

    render() {
        return (
            <div className="container login" >
                {/*<label className="title" >云石电子菜谱<br />管理系统</label>*/}
                {/* <div style={{font:30, fontWeight: "bold", left: '100px', color: 'red' , position: 'absolute'}}>{this.state.errorMsg}</div> */}
                <LoginForm onLogin={this.onLogin} isError={this.state.isError} msg={this.state.msg} />
                <div className="right-img"></div>
                {/*<image src="c" />*/}
            </div>
        );
    }
}

