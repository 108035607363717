import React from 'react'
import { Modal, Button, Input, Form, message } from 'antd';
import PropTypes from 'prop-types';

import '../../styles/addModal.css'


export default class AddModal extends React.Component {

    static propTypes = {
        onOk: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    }

    handleSubmit = e => {
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    if (this.props.initData != null) {
                        if (this.props.initData.alias === values.alias &&
                            this.props.initData.serialNum === values.serialNum) {
                            message.warn("没有修改任何内容", 0.5);
                            return;
                        }
                        values = {
                            ...values,
                            id: this.props.initData.id
                        }
                    }
                    
                    this.props.onOk(values);
                }
            },
        );
    }

    handleCancel = e => {
        this.props.onCancel();
    }

    render() {
        const { visible, upLoading } = this.props;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };
        const initData = this.props.initData;
        if (!visible) return null;

        return (
            <Modal visible={true}
                title={this.props.isEditModal ? "修改设备" : "添加设备"}

                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="cancel" onClick={this.handleCancel}>取消</Button>,
                    <Button key="submit" type="primary" loading={upLoading} onClick={this.handleSubmit}>提交</Button>
                ]}
            >
                <Form
                    layout={"horizontal"}
                >
                    <Form.Item label="别名:" {...formItemLayout}>
                        {getFieldDecorator('alias', {
                            initialValue: initData != null ? initData.alias : "",
                            rules: [{
                                required: true,
                                message: '请输入设备别名',
                            },
                            {
                                min: 2, max: 20,
                                message: '请输入2-20个字符长度'
                            }
                            ],
                        })(
                            <Input placeholder="设备别名" />
                        )}

                    </Form.Item>
                    <Form.Item label="序列号:" {...formItemLayout}>
                        {getFieldDecorator('serialNum', {
                            initialValue: initData != null ? initData.serialNum : "",
                            rules: [{
                                required: true,
                                message: '请输入设备序列号',
                            },
                            {
                                min: 10,
                                message: '请输入10位以上序列号'
                            },
                            ],
                        })(
                            <Input placeholder="设备序列号" />
                        )}

                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

AddModal = Form.create()(AddModal);