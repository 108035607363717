import React from 'react'
import {
    Table, InputNumber, Popconfirm, Form, Button, Icon, message, Divider, Select, Radio
} from 'antd';

import Apis from '../../api'
import PageInfo from '../../pages/Paging'
import Status from '../../api/Status';
import MyEmpty from '../common/MyEmpty'

const FormItem = Form.Item;
const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);
const Option = Select.Option;
const RadioGroup = Radio.Group;

const EditableFormRow = Form.create()(EditableRow);
//最大支持价格数量
const maxPriceItemCount = 3;

class EditableCell extends React.Component {

    onSelectChange = (value) => {
        // this.props.record.specificationId = value;
    }

    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber size="small" />;
        }
        return (
            <Select onChange={this.onSelectChange}>
                {
                    this.props.specData.map(element =>
                        <Option key={element.id} value={element.id}>{element.name}</Option>
                    )
                }
            </Select>
        )
    };

    getInitValue = (record) => {
        if (this.props.inputType === 'number') {
            return record[this.props.dataIndex];
        } else {
            return record[this.props.dataIndex];
        }
    }

    getNormalDisplay = (restProps) => {
        if (this.props.inputType === 'number') {
            return this.props.record[this.props.dataIndex];
        } else if (this.props.inputType === 'select') {
            return this.props.record.specificationName ? this.props.record.specificationName : this.findSpecName(this.props.record[this.props.dataIndex]);
        } else {
            return restProps.children;
        }
    }

    findSpecName = (id) => {
        var label = "未选择";
        if (id && this.props.specData) {
            this.props.specData.forEach(item => {
                if (item.id === id) {
                    label = item.name;
                }
            });
        }

        return label;
    }

    render() {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            specData,
            ...restProps
        } = this.props;
        return (
            <EditableContext.Consumer>
                {(form) => {
                    const { getFieldDecorator } = form;
                    return (
                        <td {...restProps}>
                            {editing ? (
                                <FormItem style={{ margin: 0 }}>
                                    {getFieldDecorator(dataIndex, {
                                        rules: [{
                                            required: true,
                                            message: `输入${title}`,
                                        }],
                                        initialValue: this.getInitValue(record),
                                    })(this.getInput())}
                                </FormItem>
                            ) : this.getNormalDisplay(restProps)}
                        </td>
                    );
                }}
            </EditableContext.Consumer>
        );
    }
}

const emptySpecId = "未选择";

const emptyItemData = { memberPrice: "0", price: "0", specificationId: "", order: 1 };

export default class FoodPriceForm extends React.Component {

    componentWillMount() {
        Apis.getSpecs(PageInfo.getPage(0, 99))
            .then(data => {
                data = data.data;
                this.setState({
                    ...this.state,
                    specData: data
                });
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    }

    static propTypes = {
        // currentPrice: PropTypes.bool.isRequired,
    }

    static defaultProps = {
        initData: [],
    }

    constructor(props) {
        super(props);

        this.state = {
            data: props.initData, //当前菜品规格数据
            specData: [],   //所有可选规格
            editingKey: '',
            currentPrice: props.currentPrice, //是否时价
        };

        if (props.initData || props.currentPrice === true) {
            props.priceDataChanged({
                currentPrice: props.currentPrice,
                data: props.initData
            });
        }
    }



    columns = [
        {
            title: '非会员价',
            dataIndex: 'price',
            width: '30%',
            editable: true,
        },
        // {
        //     title: '会员价',
        //     dataIndex: 'memberPrice',
        //     width: '25%',
        //     editable: true,
        // },
        {
            title: '规格',
            dataIndex: 'specificationId',
            width: '40%',
            editable: true,
        },
        {
            title: '操作',
            dataIndex: 'operation',
            render: (text, record) => {
                const editable = this.isEditing(record);
                return (
                    <div>
                        {editable ? (
                            <span>
                                <EditableContext.Consumer>
                                    {form => (
                                        <a
                                            href="javascript:;"
                                            onClick={() => {
                                                record.memberPrice = 0;
                                                this.save(form, record);
                                            }}
                                            style={{ marginRight: 8 }}
                                        >保存</a>
                                    )}
                                </EditableContext.Consumer>
                                <Divider type='vertical' />
                                <a
                                    href="javascript:;"
                                    onClick={() => this.cancel()}
                                >取消</a>
                            </span>
                        ) : (<span>
                            <a onClick={() => this.edit(record.order)}>编辑</a>
                            <Divider type='vertical' />
                            <Popconfirm
                                title="确定删除该数据？"
                                okText="删除"
                                cancelText="取消"
                                onConfirm={() => { 
                                    record.memberPrice = 0;
                                    this.delete(record.order); }
                                }
                            >
                                <a style={{ color: 'red' }} >删除</a>
                            </Popconfirm>
                        </span>
                            )}
                    </div>
                );
            },
        },
    ];

    isEditing = record => record.order === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    delete = (order) => {
        var data = this.state.data;
        for (var i = 0; i < data.length; i++) {
            if (data[i].order === order) {
                data.splice(i, 1);
                for (i = 0; i < data.length; i++) {
                    data[i].order = maxPriceItemCount - i;
                }
                this.setState({
                    ...this.state,
                    data: data,
                    editingKey: ''
                });
                this.props.priceDataChanged(data);
                this.props.priceDataChanged({
                    currentPrice: this.state.currentPrice,
                    data: data
                });
                return;
            }
        }
    };

    save(form, record) {
        form.validateFields((error, row) => {
            console.log("validate row->" + JSON.stringify(row));
            if (error) {
                return;
            }
            const newData = [...this.state.data];
            const index = newData.findIndex(item => record.order === item.order);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                this.setState({ data: newData, editingKey: '' });
            } else {
                newData.push(row);
                this.setState({ data: newData, editingKey: '' });
            }

            this.props.priceDataChanged({
                currentPrice: false,
                data: newData
            });
        });
    }

    edit(order) {
        this.setState({ editingKey: order });
    }

    handleAdd = (e) => {
        const data = this.state.data;
        if (data && data.length >= maxPriceItemCount) {
            message.warn("价格最多不超过" + maxPriceItemCount + "条");
            return;
        }

        var findEmpty = false;
        data && data.forEach(item => {
            if (item.specificationId === emptySpecId || item.specificationId === "") {
                findEmpty = true;
                return;
            }

        });

        if (findEmpty === true) {
            message.warn("请先完善已添加条目信息");
            return;
        }

        const newData = data;
        emptyItemData.order = maxPriceItemCount - data.length;
        newData.push(JSON.parse(JSON.stringify(emptyItemData)));
        this.setState({
            editingKey: emptyItemData.key,
            data: newData
        });
    }



    render() {
        // this.props.onChange(this.state.data);
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };

        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'price' || col.dataIndex === 'memberPrice' ? 'number' : 'select',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    specData: this.state.specData,
                    editing: this.isEditing(record),
                }),
            };
        });
        return (
            <div style={{ display: this.props.visible ? 'inline' : 'none' }}>


                <div style={{ marginTop: "10px" }}>
                    <span>是否时价：</span>
                    <RadioGroup onChange={(e) => {
                        this.props.priceDataChanged({
                            currentPrice: e.target.value,
                            data: this.state.data
                        });
                        this.setState({
                            ...this.state, currentPrice: e.target.value
                        })
                    }}
                        value={this.state.currentPrice}>
                        <Radio value={false}>否</Radio>
                        <Radio value={true}>是</Radio>
                    </RadioGroup>
                </div>
                <div style={{ marginTop: "15px", display: this.state.currentPrice ? 'none' : 'block' }}>
                    <Button size="small" type="primary" onClick={this.handleAdd}>
                        <Icon type="plus" /> 添加
                </Button>
                    <span style={{ marginLeft: "5px", color: "red", fontWeight: "bold", fontSize: "12px" }}>* 第一条价格为PAD默认显示价格，且系统默认价格单位为：元</span>

                    <Table
                        locale={{ emptyText: <MyEmpty /> }}
                        style={{ marginTop: "10px" }}
                        {...this.props}
                        form={this.props.form}
                        components={components}
                        rowKey={"order"}
                        bordered
                        pagination={false}
                        size="small"
                        dataSource={this.state.data}
                        columns={columns}
                        rowClassName="editable-row"
                    />
                </div>
            </div >
        );
    }
}
