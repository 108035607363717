import React from 'react'
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import {message} from 'antd'

import RegistrationForm from '../../components/RegistrationForm'
import './register.css'
import '../../styles/textstyles.css'
import Apis from '../../api/'
import Status from '../../api/Status';

export default class RegisterContainer extends React.Component {

    onRegister = (values) => {
        Apis.register({
            userName: values.userName,
            psw: values.psw,
            contact: values.contact,
            phoneNum: values.phone,
            address: values.address,
            name: values.name
        })
            .then(data => {
                Modal.success({
                    title: "注册成功",
                    content: "点击返回到登录页",
                    onOk: () => {
                        this.props.history.replace('/login')
                    }
                })
            }).catch (error => {
                message.error(Status.getMessage(error.code));
            })
    }

    render() {
        return (
            <div className='content'>
                <div className='top'>
                    <label className='register-title ' >用户注册</label>
                    <Link className='toLogin' to="/login"  replace="true">>>点击登录</Link>
                </div>

                <hr className='.head-hr' />
                <div className='form'>
                    <RegistrationForm onRegister={this.onRegister} />
                </div>
            </div>
        )
    }
}