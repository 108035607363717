import Cos from 'cos-js-sdk-v5'
import moment from 'moment'
import { message } from 'antd'

import Apis from '../api'
import Status from './Status';




class FileUtils {
    auth = null;
    cos = null;

    isValidAuth(auth) {
        if (auth != null && moment(auth.expiredTime).isBefore(moment())) {
            return true;
        }

        return false;
    }


    doUploadFile(auth, path) {

    }

    getAuthorization = (options, callback) => {
        console.log("getAuthorization begin");
        Apis.getSecret()
            .then(data => {
                this.auth = data.data;

                callback({
                    TmpSecretId: this.auth.secretId,
                    TmpSecretKey: this.auth.secretKey,
                    XCosSecurityToken: this.auth.sessionToken,
                    ExpiredTime: this.auth.expiredTime
                });
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    };

    getInstance() {
        this.auth = "";
        if (this.cos == null) {
            console.log('init cos');
            this.cos = new Cos({
                getAuthorization: this.getAuthorization
            });
        }
        return this;
    }

    guid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    uploadFile(file, callback) {
        // console.log("file->" + file.type.substring(6));
        const name = this.guid() + '.' + file.type.substring(6);
        this.cos.putObject({
            Bucket: "ymenu-1258819311",
            Region: "ap-beijing",
            Key: 'pictures/' + name,
            Body: file,
            onProgress: function (info) {
            }
        }, function (err, data) {
            console.log(err, data);
            if (!err) {
                const url = 'http://ymenu-1258819311.cos.ap-beijing.myqcloud.com/pictures/' + name;
                data = {
                    ...data,
                    url: url
                };
            }
            callback(data, err);
        });
    }
};

// const instance = new FileUtils();
// Object.freeze(instance);
// export default instance;
export default FileUtils;