import React from 'react'
import { Modal, Cascader, Button, Form, Input, InputNumber, message } from 'antd'

import Apis from '../../api'
import PageInfo from '../../pages/Paging'
import Status from '../../api/Status';

const FormItem = Form.Item;

export default class AddRankModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: this.formatHallData(this.props.hallData)
        }
    }

    formatHallData = (hallData) => {
        if (hallData && hallData.length > 0) {
            hallData = hallData.map(element => {
                return {
                    ...element,
                    isLeaf: false
                }
            });
        }

        return hallData;
    }

    addTypeChildren = (hallId, children) => {
        const oldData = this.state.options;
        const data = oldData.map(element => {
            if (element.id === hallId) {
                children = children.map(element => {
                    return {
                        ...element,
                        isLeaf: false
                    }
                });
                return {
                    ...element,
                    items: children
                }
            } else {
                return element
            }
        })

        return data;
    }

    //在菜类下添加菜品数据
    addFoodChildren = (typeId, children) => {
        const oldData = this.state.options;
        const data = oldData.map(element => {
            if (element.items) {
                const rootElemnt = element;
                const childElements = element.items.map(element => {
                    if (element.id === typeId) {
                        children = children.map(element => {
                            return {
                                ...element,
                                isLeaf: true
                            }
                        });
                        return {
                            ...element,
                            items: children
                        }
                    } else {
                        return element
                    }
                })
                return {
                    ...rootElemnt,
                    "items": childElements
                }
            } else {
                return element;
            }

        })

        return data;
    }

    isInited = false;
    initDefaultSelectedFood = (editData) => {
        this.isInited = true;

        Apis.getFoodTypes(PageInfo.getPage(0, 99), { "hallId": editData.hallId })
            .then(data => {
                const formatData = this.addTypeChildren(editData.hallId, data.data);
                this.setState({
                    ...this.state,
                    options: formatData
                });

                Apis.getFoods(PageInfo.getPage(0, 999), { "typeId": editData.typeId })
                    .then(data => {
                        const formatData = this.addFoodChildren(editData.typeId, data.data);
                        this.setState({
                            ...this.state,
                            options: formatData
                        });

                        this.props.form.setFieldsValue({
                            "foodId": [editData.hallId, editData.typeId, editData.foodId]
                        });
                    })
                    .catch(error => {
                        message.error(Status.getMessage(error.code));
                        this.setState({
                            ...this.state,
                        });
                    })
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    }

    loadData = (selectedData) => {
        if (selectedData.length === 1) { //用户点击的酒店
            Apis.getFoodTypes(PageInfo.getPage(0, 99), { "hallId": selectedData[0].id })
                .then(data => {
                    const formatData = this.addTypeChildren(selectedData[0].id, data.data);
                    this.setState({
                        ...this.state,
                        options: formatData
                    });
                })
                .catch(error => {
                    message.error(Status.getMessage(error.code));
                    this.setState({
                        ...this.state,
                    });
                })
        } else if (selectedData.length === 2) { //用户点击的菜类
            Apis.getFoods(PageInfo.getPage(0, 999), { "typeId": selectedData[1].id })
                .then(data => {
                    const formatData = this.addFoodChildren(selectedData[1].id, data.data);
                    this.setState({
                        ...this.state,
                        options: formatData
                    });
                })
                .catch(error => {
                    message.error(Status.getMessage(error.code));
                    this.setState({
                        ...this.state,
                    });
                })
        }

    }

    handleOk = () => {
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    values = {
                        ...values,
                        "foodId": this.props.data ? this.props.data.foodId : values.foodId[2],
                        "id": this.props.data ? this.props.data.id : null
                    }
                    this.props.onOk(values);
                }
            }
        )
    }

    displayRender = (label) => {
        return label[label.length - 1];
    }

    render() {
        const { visible, upLoading, onCancel, isEditModal } = this.props;
        if (!visible) return null;

        const initData = this.props.data;

        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };

        // if (isEditModal && !this.isInited) this.initDefaultSelectedFood(initData);
        return (
            <Modal
                title={this.props.isEditModal ? "修改排行" : "添加排行"}
                onOk={this.handleOk}
                visible={true}
                onCancel={onCancel}
                footer={[
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="submit" type="primary" loading={upLoading} onClick={this.handleOk}>提交</Button>
                ]}
            >
                <Form
                    layout={"horizontal"}
                >

                    {!isEditModal &&
                        <FormItem label="菜品:" {...formItemLayout}>
                            {getFieldDecorator('foodId', {
                                rules: [{
                                    required: true,
                                    message: '请选择菜品',
                                }
                                ],
                            })(
                                <Cascader
                                    options={this.state.options}
                                    fieldNames={{ label: 'name', value: 'id', children: 'items' }}
                                    loadData={this.loadData}
                                    disabled={isEditModal}
                                    displayRender={this.displayRender}
                                    placeholder="选择菜品" />
                            )}

                        </FormItem>
                    }

                    {isEditModal &&
                        <FormItem label="菜品:" {...formItemLayout}>
                            {getFieldDecorator('foodId', {
                                initialValue: initData.foodName,
                                rules: [{
                                    required: true,
                                    message: '请选择菜品',
                                }
                                ],
                            })(
                                <Input disabled={true}/>
                            )}

                        </FormItem>
                    }

                    <FormItem label="排序号:" {...formItemLayout}>
                        {getFieldDecorator('rank', {
                            initialValue: initData == null ? this.props.index : initData.rank,
                            rules: [{
                                required: true,
                                message: '请输入排序号',
                            }
                            ],
                        })(
                            <InputNumber placeholder="菜品排序号" 
                            defaultValue={initData == null ? this.props.index : initData.rank}  />
                        )}

                    </FormItem>
                </Form>
            </Modal >
        )
    }
}
AddRankModal = Form.create()(AddRankModal);