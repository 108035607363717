// import { Modal } from 'antd'

import Config from "./config"
import Status from './Status'
import CommonError from '../exception/CommonError'
import FetchError from '../exception/FetchError'

import Storage from '../util/Storage'

// const defaultErrorHandler = (code, msg) => {
//     console.log("error==>" + Status.getMessage(code));
//     Modal.warn({
//         title: "提示",
//         content: Status.getMessage(code),
//     })
// }
const xUploadSomething = (url, method, body) => {
    console.log('url==>' + url);
    console.log("request " + method + '==>' + body);
    return new Promise((resolve, reject) => {
        fetch(url,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
                    'Authorization': Storage.getAuth()
                },
                method: method,
                mode: 'cors',
                body: body
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new FetchError(Status.FAILED.code);
                }
            })
            .then((jsonData) => {
                console.log("response ->" + JSON.stringify(jsonData));
                if (jsonData.code === Status.OK) {
                    resolve(jsonData);
                } else {
                    throw new CommonError(jsonData.code, jsonData.message);
                }
            })
            .catch(error => {
                console.log('error->' + error)
                reject({ code: error.code, msg: error.message });
                // if (onError) onError(error.code, error.message);
            })
    })
}
const xPost = (url, body) => {
    return xUploadSomething(url, "post", body);
}

const xPut = (url, body) => {
    return xUploadSomething(url, "put", body);
}

const xDelete = (url, body) => {
    return xUploadSomething(url, "delete", body);
}

const xGet = (url) => {
    console.log('request(get) ==>' + url);
    return new Promise((resolve, reject) => {
        fetch(url,
            {
                headers: {
                    // "Content-Type": "application/json",
                    // 'Accept': 'application/json',
                    'Authorization': Storage.getAuth()
                },
                method: 'get',
                mode: 'cors'
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new FetchError(Status.FAILED.code);
                }
            })
            .then((jsonData) => {
                if (jsonData.code === Status.OK) {
                    resolve(jsonData);
                }
                else {
                    throw new CommonError(jsonData.code, jsonData.message);
                }
            })
            .catch(error => {
                reject({ code: error.code, msg: error.message });
            })
    })
}

const login = (userName, psw) => {
    return xPost(Config.URL_ROOT + "auth/signin",
        JSON.stringify({ "userName": userName, "psw": psw }),
    )
}

const adminLogin = (userName, psw) => {
    return xPost(Config.URL_ROOT + "auth/adminSignin",
        JSON.stringify({ "userName": userName, "psw": psw }),
    )
}

const register = (registrationInfo) => xPost(Config.URL_ROOT + "auth/signup",
    JSON.stringify(registrationInfo)
);

const getHalls = (pageInfo, filters = {}) => {
    var url = Config.URL_ROOT + "halls";
    let params = [];
    if (pageInfo != null) {
        Object.keys(pageInfo).forEach(key => { params.push(key + "=" + pageInfo[key]) });
        Object.keys(filters).forEach(key => {
            const value = filters[key];
            if (value != null) params.push(key + "=" + value);
        });
        url = url + "?" + params.join("&");
    }

    return xGet(url);
}

const addHall = (data) => {
    return xPost(Config.URL_ROOT + "halls", JSON.stringify(data));
}

const updateHall = (data) => {
    return xPut(Config.URL_ROOT + "halls/" + data.id, JSON.stringify(data));
}

const deleteHall = (data) => {
    return xDelete(Config.URL_ROOT + "halls/" + data.id, JSON.stringify(data));
}

const deleteHalls = (data) => {
    return xDelete(Config.URL_ROOT + "halls/", JSON.stringify(data));
}

const getFoodTypes = (pageInfo, filters = {}) => {
    var url = Config.URL_ROOT + "foods/types";
    let params = [];
    if (pageInfo != null) {
        Object.keys(pageInfo).forEach(key => { params.push(key + "=" + pageInfo[key]) });
        Object.keys(filters).forEach(key => {
            const value = filters[key];
            if (value != null) params.push(key + "=" + value);
        });
        url = url + "?" + params.join("&");
    }

    return xGet(url);
}

const addFoodType = (data) => {
    return xPost(Config.URL_ROOT + "foods/types/", JSON.stringify(data));
}

const updateFoodType = (data) => {
    return xPut(Config.URL_ROOT + "foods/types/" + data.id, JSON.stringify(data));
}

const deleteFoodType = (data) => {
    return xDelete(Config.URL_ROOT + "foods/types/" + data.id, JSON.stringify(data));
}

const deleteFoodTypes = (data) => {
    return xDelete(Config.URL_ROOT + "foods/types/", JSON.stringify(data));
}

const getTasteTypes = (pageInfo, filters = {}) => {
    var url = Config.URL_ROOT + "tastes";
    let params = [];
    if (pageInfo != null) {
        Object.keys(pageInfo).forEach(key => { params.push(key + "=" + pageInfo[key]) });
        Object.keys(filters).forEach(key => {
            const value = filters[key];
            if (value != null) params.push(key + "=" + value);
        });
        url = url + "?" + params.join("&");
    }

    return xGet(url);
}

const getSpecs = (pageInfo) => {
    var url = Config.URL_ROOT + "specs";
    let params = [];
    if (pageInfo != null) {
        Object.keys(pageInfo).forEach(key => { params.push(key + "=" + pageInfo[key]) });
        url = url + "?" + params.join("&");
    }

    return xGet(url);
}

const addSpec = (data) => {
    return xPost(Config.URL_ROOT + "specs", JSON.stringify(data));
}

const updateSpec = (data) => {
    return xPut(Config.URL_ROOT + "specs/" + data.id, JSON.stringify(data));
}

const deleteSpec = (data) => {
    return xDelete(Config.URL_ROOT + "specs/" + data.id, JSON.stringify(data));
}

const getFoods = (pageInfo, filters = {}) => {
    var url = Config.URL_ROOT + "foods/";
    let params = [];
    if (pageInfo != null) {
        Object.keys(pageInfo).forEach(key => { params.push(key + "=" + pageInfo[key]) });
        Object.keys(filters).forEach(key => {
            const value = filters[key];
            if (value != null) params.push(key + "=" + value);
        });
        url = url + "?" + params.join("&");
    }

    return xGet(url);
}

const addFood = (data) => {
    return xPost(Config.URL_ROOT + "foods/", JSON.stringify(data));
}

const updateFood = (data) => {
    return xPut(Config.URL_ROOT + "foods/" + data.id, JSON.stringify(data));
}

const deleteFood = (data) => {
    return xDelete(Config.URL_ROOT + "foods/" + data.id, JSON.stringify(data));
}

const deleteFoods = (data) => {
    return xDelete(Config.URL_ROOT + "foods/", JSON.stringify(data));
}

const getHallWithTypes = () => {
    var url = Config.URL_ROOT + "halls/types";

    return xGet(url);
}

const getRanks = (hallId, pageInfo) => {
    var url = Config.URL_ROOT + "ranks";
    let params = [];
    if (pageInfo != null) {
        Object.keys(pageInfo).forEach(key => { params.push(key + "=" + pageInfo[key]) });
        url = url + "/" + hallId + "?" + params.join("&");
    }

    return xGet(url);
}

const addRank = (data) => {
    return xPost(Config.URL_ROOT + "ranks/", JSON.stringify(data));
}

const updateRank = (data) => {
    return xPut(Config.URL_ROOT + "ranks/" + data.id, JSON.stringify(data));
}

const deleteRank = (data) => {
    return xDelete(Config.URL_ROOT + "ranks/" + data.id, JSON.stringify(data));
}


const getIntroductions = (pageInfo, filters = {}) => {
    var url = Config.URL_ROOT + "intros/";
    let params = [];
    if (pageInfo != null) {
        Object.keys(pageInfo).forEach(key => { params.push(key + "=" + pageInfo[key]) });
        Object.keys(filters).forEach(key => {
            const value = filters[key];
            if (value != null) params.push(key + "=" + value);
        });
        url = url + "?" + params.join("&");
    }

    return xGet(url);
}

const addIntroduction = (data) => {
    return xPost(Config.URL_ROOT + "intros/", JSON.stringify(data));
}

const updateIntroduction = (data) => {
    return xPut(Config.URL_ROOT + "intros/" + data.id, JSON.stringify(data));
}

const deleteIntroduction = (data) => {
    return xDelete(Config.URL_ROOT + "intros/" + data.id, JSON.stringify(data));
}

const getDevices = (pageInfo, filters = {}) => {
    var url = Config.URL_ROOT + "devices/";
    let params = [];
    if (pageInfo != null) {
        Object.keys(pageInfo).forEach(key => { params.push(key + "=" + pageInfo[key]) });
        Object.keys(filters).forEach(key => {
            const value = filters[key];
            if (value != null) params.push(key + "=" + value);
        });
        url = url + "?" + params.join("&");
    }

    return xGet(url);
}

const addDevice = (data) => {
    return xPost(Config.URL_ROOT + "devices/", JSON.stringify(data));
}

const updateDevice = (data) => {
    return xPut(Config.URL_ROOT + "devices/" + data.id, JSON.stringify(data));
}

const deleteDevice = (data) => {
    return xDelete(Config.URL_ROOT + "devices/" + data.id, JSON.stringify(data));
}

const getSecret = () => {
    var url = Config.URL_ROOT + "acloud/";
    return xGet(url);
}

const searchSimpleFoods = (pageInfo, name) => {
    var url = Config.URL_ROOT + "foods/simple";
    let params = [];
    if (pageInfo != null) {
        Object.keys(pageInfo).forEach(key => { params.push(key + "=" + pageInfo[key]) });
        url = url + "?" + params.join("&") + "&name=" + name;
    }

    return xGet(url);
}

//======admin ======

const getUsers = (pageInfo, filters = {}) => {
    console.log("filters=>" + JSON.stringify(filters));
    var url = Config.URL_ROOT + "users/";
    let params = [];
    if (pageInfo != null) {
        Object.keys(pageInfo).forEach(key => { params.push(key + "=" + pageInfo[key]) });
        if (filters) {
            Object.keys(filters).forEach(key => {
                const value = filters[key];
                if (value != null) params.push(key + "=" + value);
            });
        }
        url = url + "?" + params.join("&");
    }

    return xGet(url);
}

const activeUser = (data) => {
    return xPut(Config.URL_ROOT + "users/active/" + data.id, JSON.stringify(data));
}

const blockUser = (data) => {
    return xPut(Config.URL_ROOT + "users/block/" + data.id, JSON.stringify(data));
}


const deleteUser = (data) => {
    return xDelete(Config.URL_ROOT + "users/" + data.id, JSON.stringify(data));
}

const getDeviceDetails = (pageInfo, userId) => {
    var url = Config.URL_ROOT + "devices/detail/" + userId;
    let params = [];
    if (pageInfo != null) {
        Object.keys(pageInfo).forEach(key => { params.push(key + "=" + pageInfo[key]) });
        url = url + "?" + params.join("&");
    }

    return xGet(url);
}

const activeDevice = (deviceId, startTime, endTime) => {
    return xPut(Config.URL_ROOT + "devices/active/" + deviceId, JSON.stringify({
        "startTime": startTime,
        "endTime": endTime,
    }));
}

const blockDevice = (id) => {
    return xPut(Config.URL_ROOT + "devices/block/" + id, "");
}

export default {
    login,
    register,
    getHalls,
    addHall,
    updateHall,
    deleteHall,
    deleteHalls,
    getFoodTypes,
    addFoodType,
    updateFoodType,
    deleteFoodType,
    deleteFoodTypes,
    getTasteTypes,
    getSpecs,
    addSpec,
    updateSpec,
    deleteSpec,
    getFoods,
    addFood,
    updateFood,
    deleteFood,
    deleteFoods,
    getHallWithTypes,
    getRanks,
    addRank,
    updateRank,
    deleteRank,
    getIntroductions,
    addIntroduction,
    updateIntroduction,
    deleteIntroduction,
    getDevices,
    deleteDevice,
    updateDevice,
    addDevice,
    getUsers,
    activeUser,
    blockUser,
    deleteUser,
    getDeviceDetails,
    activeDevice,
    blockDevice,
    getSecret,
    searchSimpleFoods,
}