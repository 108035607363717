import React from 'react'

import  '../styles/main.css'

export default class Copyright extends React.Component {
    render() {
        return (
            < div >
                <span className="footer-desc ">Copyright © 2018-2019 河北云石科技有限公司 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系电话:152-1036-1969</span>
            </div >
        );
    }
}