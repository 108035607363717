import React from 'react'
import { Table, message, Popconfirm, Divider, Modal, Pagination } from 'antd';

import MainToolbar from '../../components/MainToolbar'
import Apis from '../../api/'
import PageInfo from '../../pages/Paging'
import AddModal from './AddSpecificationModal'

import '../../styles/pagination.css'
import Status from '../../api/Status';
import MyEmpty from '../common/MyEmpty'

export default class SpecificationsContainer extends React.Component {
    state = {
        addModalVisible: false, //显示新增页面
        isEditModal: false, //是否是修改item数据  
        editData: null, //修改的item数据
        upLoading: false,   //添加数据时是否正在上传数据标识

        deleteConfirmModalVisible: false,   //是否弹出删除确认框

        loading: false, //图标数据加载状态
        selectedRowKeys: [], // 已选中表单项id

        currentPage: 1, //当前页面页码
        totalElements: 0,   //总数据条数（table数据条数）

    };
    selectedRows = [];
    data = [];  //表格数据

    columns = [{
        title: '名称',
        dataIndex: 'name',
    },
    {
        title: '英文名称',
        dataIndex: 'enName',
    },
    {
        title: '操作',
        width: "150px",
        dataIndex: 'operation',
        render: (text, record) => (
            this.data.length >= 1
                ? (
                    <span>
                        <a href="javascript:;" onClick={() => this.onEditItemClick(record)}>修改</a>
                        <Divider type="vertical" />
                        <Popconfirm title="确定删除该条数据?" okText="删除" okType="danger" cancelText="取消" onConfirm={() =>
                            this.deleteItem(record)
                        }
                        >
                            <a href="javascript:;" style={{ color: "red" }}>删除</a>
                        </Popconfirm >
                    </span>
                ) : null
        ),
    }
    ];


    start = () => {
        this.loadPage(0);
    }

    loadPage = (pageIndex) => {
        console.log("load page. filters ->" + JSON.stringify(this.filters));
        this.setState({
            ...this.state,
            addModalVisible: false,
            deleteConfirmModalVisible: false,
            loading: true
        });
        Apis.getSpecs(PageInfo.getPage(pageIndex))
            .then(data => {
                this.data = data.data;
                this.setState({
                    loading: false,
                    currentPage: pageIndex + 1,
                    totalElements: data.totalElements
                });
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    }


    onAddClick = (e) => {
        this.setState({
            ...this.state,
            addModalVisible: true,
            isEditModal: false,
            editData: null
        });
    }


    onEditItemClick = (data) => {
        this.setState({
            ...this.state,
            addModalVisible: true,
            isEditModal: true,
            editData: data
        });
    }

    onPageChange = (page, pageSize) => {
        console.log("onPageChange page->" + page);
        //页码，需要减一
        this.loadPage(page - 1);
    }

    addSpec = (data) => {
        Apis.addSpec(data)
            .then(data => {
                this.start();
            })
            .catch(error => {
                console.log(error);
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    addModalVisible: false
                });
            })
    }

    editSpec = (data) => {
        Apis.updateSpec(data)
            .then(data => {
                this.start();
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    addModalVisible: false
                });
            })
    }

    deleteItem = (record) => {
        Apis.deleteSpec(record)
            .then(data => {
                this.start();
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    }

    onAddModalCancelClick = e => {
        this.setState({
            ...this.state,
            addModalVisible: false,
            isEditModal: false,
            editData: null
        })
    }

    componentDidMount() {
        this.start();
    }

    render() {
        const { loading } = this.state;
        return (
            <div>
                <MainToolbar
                    showFilter={false}
                    showBatchDelete={false}
                    showSearch={false}
                    addClick={this.onAddClick} />
                <Table
                locale={{ emptyText: <MyEmpty /> }}
                 pagination={false} rowKey={'id'} columns={this.columns} dataSource={this.data} bordered loading={loading} />
                <Pagination current={this.state.currentPage} onChange={this.onPageChange} total={this.state.totalElements} className="right" />
                <AddModal isEditModal={this.state.isEditModal}
                    initData={this.state.editData}
                    handleOk={this.state.isEditModal ? this.editSpec : this.addSpec}
                    handleCancel={this.onAddModalCancelClick} visible={this.state.addModalVisible}
                    upLoading={this.state.upLoading} />

                <Modal
                    title="确认"
                    okText="删除"
                    okType="danger"
                    cancelText="取消"
                    visible={this.state.deleteConfirmModalVisible}
                    onCancel={() => this.setState({
                        ...this.state,
                        deleteConfirmModalVisible: false
                    })}
                    onOk={this.handleConfirmDeleteOk}
                >
                    <p>确认删除已选中的记录吗？</p>
                </Modal>
            </div>

        );
    }
}

