import React from 'react'
import {
  Form, Input, Select, Button
} from 'antd';

const FormItem = Form.Item;
const Option = Select.Option;

class RegistrationForm extends React.Component {
  state = {
    confirmDirty: false
  };


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onRegister(values);
      }
    });
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('psw')) {
      callback('两次输入的密码不一致');
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['spsw'], { force: true });
    }
    callback();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '86',
    })(
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
      </Select>
    );
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem
          {...formItemLayout}
          label="用户名"
        >
          {getFieldDecorator('userName', {
            rules: [{
              required: true, message: '请输入您的用户名',
            },
            {
              min: 5, max: 10,
              message: '请输入5-10个字符长度'
            }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="密码"
        >
          {getFieldDecorator('psw', {
            rules: [{
              required: true, message: '请输入您的密码',
            }, {
              min: 6,
              message: '请输入至少6位密码'
            }, {
              validator: this.validateToNextPassword,
            }],
          })(
            <Input.Password placeholder="请确认您的密码" />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="确认密码"
        >
          {getFieldDecorator('confirm', {
            rules: [{
              required: true, message: '请再次输入您的密码',
            }, {
              validator: this.compareToFirstPassword,
            }],
          })(
            <Input.Password placeholder="请确认您的密码" onBlur={this.handleConfirmBlur} />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="酒店名称"
        >
          {getFieldDecorator('name', {
            rules: [{
              required: true,
              message: '请输入酒店名称',
            }, {
              min: 2,
              message: '请输入至少2个字符'
            }],
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="联系人"
        >
          {getFieldDecorator('contact', {
            rules: [{
              required: true, message: '请输入联系人',
            }, {
              min: 2,
              message: '请输入至少2个字符'
            }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="联系电话"
        >
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: '请输入您的联系电话' }],
          })(
            <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="联系地址"
        >
          {getFieldDecorator('address', {
            rules: [{
              required: true, message: '请输入酒店名称或地址',
            }],
          })(
            <Input />
          )}
        </FormItem>
        {/* <FormItem {...tailFormItemLayout}>
            {getFieldDecorator('agreement', {
              valuePropName: 'checked',
            })(
              <Checkbox>I have read the <a href="">agreement</a></Checkbox>
            )}
          </FormItem> */}
        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">注 册</Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(RegistrationForm);
