import React from 'react'

import { Table, message, Popconfirm, Divider, Modal, Pagination, Tag } from 'antd';

import MainToolbar from './FoodToolbar'
import Apis from '../../api'
import PageInfo from '../../pages/Paging'
import AddModal from './AddFoodModal'
import DescriptionModal from './FoodDescriptionModal'
import '../../styles/pagination.css'
import StringUtils from '../../util/StringUtils'
import Config from '../../api/config'
import Status from '../../api/Status';
import MyEmpty from '../common/MyEmpty'
import EditFoodPriceModal from './EditFoodPriceModal';

export default class FoodsContainer extends React.Component {
    state = {
        addModalVisible: false, //显示新增页面
        isEditModal: false, //是否是修改item数据
        isEditPrice: false, //是否是修改价格数据
        editData: null, //修改的item数据
        upLoading: false,   //添加数据时是否正在上传数据标识

        deleteConfirmModalVisible: false,   //是否弹出删除确认框

        loading: false, //图标数据加载状态
        selectedRowKeys: [], // 已选中表单项id

        currentPage: 1, //当前页面页码
        totalElements: 0,   //总数据条数（table数据条数）


    };
    selectedRows = [];
    data = [];  //表格数据
    hallData = [];  //餐厅数据
    foodTypesData = [];//餐厅对应的菜类数据，格式:[{hall: {}, types: []}, ...]
    hallTypes = []; //当前选中的hall对应的type数据
    filters = {};

    columns = [{
        title: '名称',
        dataIndex: 'name',
        // width: "200px",
    }, {
        title: '英文名称',
        dataIndex: 'enName',
        width: "200px",
    }, {
        title: '图片',
        width: '100px',
        dataIndex: 'image',
        render: (data, record, index) => {
            const type = this.getTypeById(record.typeId);
            if (type && type.showPicture) {
                if (record.picture) {
                    return <img src={record.picture} alt="" style={{ width: "80px", height: "50px" }} />
                } else {
                    return <span style={{ color: "red" }}>未设置</span>
                }
            } else {
                return '--'
            }
        }
    }, {
        title: '餐厅',
        dataIndex: 'hall',
        width: "100px",
        render: (data, record, index) => {
            const hall = this.getHallByTypeId(record.typeId);
            return hall == null ? "未知" : hall.name;
        }
    }, {
        title: '类别',
        dataIndex: 'type',
        width: "100px",
        render: (data, record, index) => {
            return record.typeName;
        }
    }, {
        title: '口味',
        dataIndex: 'tastes',
        width: "125px",
        render: tastes => {
            if (tastes == null || tastes.length < 1) {
                return "--"
            } else {
                return (
                    < span >
                        {
                            tastes.map(tag => {
                                let color = 'blue';
                                if (tag.tasteName === '辣') {
                                    color = 'volcano';
                                } else if (tag.tasteName === '鲜') {
                                    color = 'green';
                                }

                                return <Tag color={color} key={tag.tasteId}>{tag.tasteName}</Tag>;
                            })
                        }
                    </span >
                )
            }
        }
    }, {
        title: '价格',  //时价或详情（点击查看规格价格）
        width: "150px",
        dataIndex: 'currentPrice',
        render: (text, record, index) => {
            if (text === true) {
                return <label style={{ color: 'red' }}>时价</label>
            } else {
                return <label style={{ whiteSpace: 'pre-wrap' }}>{this.getPricesString(index)}</label>
            }
        }
    }, {
        title: '排序号',
        width: "80px",
        dataIndex: 'order',
    },
    {
        title: '操作',
        width: "200px",
        dataIndex: 'operation',
        render: (text, record) => (
            this.data.length >= 1
                ? (
                    <span>
                        <a href="javascript:;" onClick={() => {
                            this.setState({
                                ...this.state,
                                descModalVisible: true,
                                itemDescData: { "desc": record.description, "enDesc": record.enDescription }
                            })
                        }}>介绍</a>
                        <Divider type="vertical" />
                        <a href="javascript:;" onClick={() => this.onEditPriceClick(record)}>价格</a>
                        <Divider type="vertical" />
                        <a href="javascript:;" onClick={() => this.onEditItemClick(record)}>修改</a>
                        <Divider type="vertical" />
                        <Popconfirm title="确定删除该条数据?" okText="删除" okType="danger" cancelText="取消" onConfirm={(e) => {
                            e.preventDefault();
                            this.deleteItem(record);
                        }
                        }
                        >
                            <a href="javascript:;" style={{ color: "red" }}>删除</a>
                        </Popconfirm >
                    </span>
                ) : null
        ),
    }
    ];

    detailColumns = [{
        title: '规格',
        dataIndex: 'specification',
        render: (data, record, index) => {
            return record.specificationName;
        }
    }, {
        title: '会员价',
        dataIndex: 'image',
        render: (data, record, index) => {
            return (<span style={{ fontWeight: "bold" }}>￥{record.memberPrice}</span>);
        }
    }, {
        title: '非会员价',
        dataIndex: 'price',
        render: (data, record, index) => {
            return (<span style={{ fontWeight: "bold" }}>￥{record.price}</span>);
        }
    },
    ];

    expandedRowRender = (record, index, indent, expanded) => {
        if (record.specifications == null || record.specifications.length < 1) {
            return null;
        } else {
            return (
                <Table
                    locale={{ emptyText: <MyEmpty /> }}
                    columns={this.detailColumns}
                    dataSource={this.data[index].specifications}
                    bordered={false}
                    pagination={false}
                />)
        }
    }

    getPricesString = (index) => {
        var prices = this.data[index].specifications;
        var result = "";
        prices && prices.forEach(element => {
            if (element.price === element.memberPrice) {
                result = result + "￥" + element.price;
            } else {
                //取消会员价显示
                // result = result + "￥" + element.memberPrice + "/" + element.price;
                result = result + "￥" + element.price;
            }
            result = result + "/" + element.specificationName + '\n'
        });

        return result;
    }


    getHallById = (id) => {
        // console.log("hallData->" + JSON.stringify(this.hallData) + "    id->" + id);
        const halls = this.hallData;
        if (halls != null) {
            var tmp;
            halls.forEach(hall => {
                if (hall.id === id) {
                    tmp = hall;
                }
            });
            return tmp;
        }
    }

    getHallByTypeId = (typeId) => {
        var tmp;
        this.foodTypesData.forEach(item => {
            item.types.forEach(type => {
                if (type.id === typeId) {
                    tmp = item.hall;
                }
            })

        });
        return tmp;
    }

    getTypeById = (typeId) => {
        var tmp;
        this.foodTypesData.forEach(item => {
            item.types.forEach(type => {
                if (type.id === typeId) {
                    tmp = type;
                }
            })

        });
        return tmp;
    }

    start = () => {
        this.setState({
            ...this.state,
            addModalVisible: false,
            isEditPrice: false,
            loading: true
        });

        Apis.getHallWithTypes()
            .then(data => {
                if (data.data == null || data.data.length === 0) {
                    message.error("请先添加餐厅");
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    return;
                }
                this.foodTypesData = data.data;
                this.hallData = this.initHalls(this.foodTypesData);
                this.loadPage(0);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
    }

    initHalls = (data) => {
        var result = [];
        data.forEach(element => {
            result.push(element.hall);
        });

        return result;
    }

    findTypes = (hallId) => {
        var types = [];
        this.foodTypesData.forEach(item => {
            if (item.hall.id === hallId) {
                types = item.types;
            }
        });
        return types;
    }


    loadPage = (pageIndex) => {
        this.setState({
            ...this.state,
            loading: true
        });
        Apis.getFoods(PageInfo.getPage(pageIndex), this.filters)
            .then(data => {
                this.data = data.data;
                this.setState({
                    ...this.state,
                    loading: false,
                    currentPage: pageIndex + 1,
                    totalElements: data.totalElements
                });
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    loading: false
                });
            })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.selectedRows = selectedRows;
        this.setState({
            ...this.state,
            selectedRowKeys
        });
    }

    onAddClick = (e) => {
        console.log("foodTypesData->" + JSON.stringify(this.foodTypesData));
        if (!this.foodTypesData || this.foodTypesData.length === 0) {
            message.error("请先添加餐厅");
            return;
        }
        this.setState({
            ...this.state,
            addModalVisible: true,
            isEditModal: false,
            editData: null
        });
    }

    onBatchDeleteClick = (e) => {
        const selectedData = this.selectedRows;
        if (selectedData == null || selectedData.length < 1) {
            message.info("请选择要删除的行");
        } else {
            Modal.confirm({
                title: '您确定要删除已选中的数据吗？',
                okText: "删除",
                cancelText: "取消",
                onOk: () => {
                    console.log("xxx1" + JSON.stringify(selectedData));
                    this.deleteFoodsInBatch(selectedData);
                }
            });
        }
    }

    onSearchClick = (value, event) => {
        if (value === this.filters.name) return;
        const data = StringUtils.trim(value);
        this.filters = {
            ...this.filters,
            name: data
        }

        this.loadPage(0);
    }

    onEditItemClick = (data) => {
        this.setState({
            ...this.state,
            addModalVisible: true,
            isEditModal: true,
            editData: data
        });
    }

    onEditPriceClick = (data) => {
        this.setState({
            ...this.state,
            addModalVisible: false,
            isEditModal: false,
            isEditPrice: true,
            editData: data
        });
    }

    onPageChange = (page, pageSize) => {
        console.log("onPageChange page->" + page);
        //页码，需要减一
        this.loadPage(page - 1);
    }

    addFood = (data) => {
        Apis.addFood(data)
            .then(data => {
                this.start();
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    addModalVisible: false
                });
            });
    }

    editFood = (data) => {
        console.log("origin data->" + JSON.stringify(this.state.editData));
        console.log("edited data->" + JSON.stringify(data));
        data.id = this.state.editData.id;
        Apis.updateFood(data)
            .then(data => {
                this.start();
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    addModalVisible: false,
                    isEditPrice: false,
                });
            })
    }

    deleteItem = (record) => {

        Apis.deleteFood(record)
            .then(data => {
                this.start();
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    }

    deleteFoodsInBatch = (records) => {
        Apis.deleteFoods(records)
            .then(data => {
                this.start();
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    }

    onAddModalCancelClick = e => {
        this.setState({
            ...this.state,
            addModalVisible: false,
            isEditModal: false,
            editData: null
        })
    }

    onEditPriceModalCancelClick = e => {
        this.setState({
            ...this.state,
            addModalVisible: false,
            isEditModal: false,
            isEditPrice: false,
            editData: null
        })
    }

    selectedHallChanged = (key) => {
        if (key === Config.KEY_ALL) {
            key = null;
            this.filters = {
                ...this.filters,
                hallId: key,
                typeId: null,
            }
            this.hallTypes = [];
            this.loadPage(0);
        } else {
            this.filters = {
                ...this.filters,
                hallId: key,
                typeId: null,
            };
            this.hallTypes = this.findTypes(key);
            this.loadPage(0);
        }

    }

    selectedFoodTypeChanged = (key) => {

        if (key === Config.KEY_ALL) {
            key = null;
        }
        this.filters = {
            ...this.filters,
            typeId: key
        }
        this.loadPage(0);
    }

    componentDidMount() {
        this.start();

    }

    render() {
        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <MainToolbar
                    showBatchDelete={false}
                    selectHallsData={this.hallData}
                    selectFoodTypesData={this.filters.hallId == null ? null : this.hallTypes}
                    hallChanged={this.selectedHallChanged}
                    foodTypeChanged={this.selectedFoodTypeChanged}
                    selectTitle={"选择餐厅"}
                    batchDeleteClick={this.onBatchDeleteClick}
                    addClick={this.onAddClick}
                    searchClick={this.onSearchClick} />
                <Table pagination={false}
                    locale={{ emptyText: <MyEmpty /> }}
                    rowSelection={rowSelection}
                    rowKey={'id'}
                    // scroll={{y: '620px' }}
                    // expandRowByClick={true}
                    columns={this.columns}
                    dataSource={this.data}
                    size='middle'
                    bordered
                    loading={loading}
                // expandedRowRender={this.expandedRowRender}
                />
                <Pagination current={this.state.currentPage} onChange={this.onPageChange} total={this.state.totalElements} className="right" />
                {
                    this.state.addModalVisible ?
                        <AddModal isEditModal={this.state.isEditModal}
                            initData={this.state.editData}
                            hallData={this.hallData}
                            foodTypesData={this.foodTypesData}
                            index={this.state.totalElements + 1}
                            handleOk={this.state.isEditModal ? this.editFood : this.addFood}
                            handleCancel={this.onAddModalCancelClick}
                            visible={this.state.addModalVisible}
                            upLoading={this.state.upLoading} />
                        :
                        null
                }

                {
                    this.state.isEditPrice ?
                        <EditFoodPriceModal
                            initData={this.state.editData}
                            index={this.state.totalElements + 1}
                            handleOk={this.editFood }
                            handleCancel={this.onEditPriceModalCancelClick}
                            visible={true}
                            upLoading={this.state.upLoading} />
                        :
                        null
                }

                <Modal
                    title="确认"
                    okText="删除"
                    okType="danger"
                    cancelText="取消"
                    visible={this.state.deleteConfirmModalVisible}
                    onCancel={() => this.setState({
                        ...this.state,
                        deleteConfirmModalVisible: false
                    })}
                    onOk={this.handleConfirmDeleteOk}
                >
                    <p>确认删除已选中的记录吗？</p>
                </Modal>

                <DescriptionModal
                    visible={this.state.descModalVisible}
                    data={this.state.itemDescData}
                    onCancel={
                        () => {
                            this.setState({
                                ...this.state,
                                descModalVisible: false
                            })
                        }
                    } />
            </div>

        );
    }
}
