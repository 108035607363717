import React from 'react';
import { Icon } from 'antd';

import '../styles/main.css';
import Storage from '../util/Storage';

export default class MainHeader extends React.Component {

    // static propTypes = {
    //     userName: string.isRequired
    // }

    // static defaultProps = {
    //     userName: "未知"
    // }

    user = Storage.getUser();


    handleLogout = (e) => {
        e.preventDefault();
        Storage.clearAuth();
        Storage.clearUser();
        this.props.history.replace('/login');
    }

    render() {
        return (
            <div className='header '>
                <label className='header-title'>YMenu 后台管理系统</label>
                <div className='user-info'>
                    <Icon type="user" />
                    <label className='icon-text-margin'>{this.user ? this.user.userName : ''}</label>
                </div>

                <div className='logout' onClick={this.handleLogout} >
                    <Icon type="logout" />
                    <label className='icon-text-margin'>退出登录</label>
                </div>
            </div>
        );

    }

}