import React from 'react';
import {
    Select, Button, Input
} from 'antd';

import '../../styles/maintoolbar.css'
import Config from '../../api/config'

const Option = Select.Option;
export default class MainToolbar extends React.Component {

    static defaultProps = {
        showFilter: true,
        showAllSelectItem: true,
        selectData: [], //item数据结构： {id,  name}
        showAdd: true,
        showBatchDelete: true,
        showSearch: true,
        searchPlaceHolder: '请输入名称搜索',
    }

    makeSelectItems(data) {
        if (data != null && data.length > 0) {
            return (
                // 
                <Select style={{ width: 200, display: this.props.showFilter ? 'inline' : 'none' }}
                    onSelect={this.handleFilterChanged}
                    defaultValue={this.props.initSelectItem ? this.props.initSelectItem : ""}
                >
                    {this.showAllSelectItem && <Option key={Config.KEY_ALL}>全部</Option>}
                    {
                        data.map(element =>
                            <Option key={element.id}>{element.name}</Option>
                        )
                    }
                </Select>

            )
        } else {
            return null
        }
    }

    handleFilterChanged = (key) => {
        this.props.selectFilterChanged(key);
    }

    handleAddClick = e => {
        this.props.addClick(e);
    }

    handleBatchDeletedClick = e => {
        this.props.batchDeleteClick(e);
    }

    render() {
        return (
            <div className='toolbar'>
                {
                    this.makeSelectItems(this.props.selectData)
                }

                <Button type="primary" style={{ marginLeft: 8, display: this.props.showAdd ? 'inline' : 'none' }} onClick={this.handleAddClick}>新增</Button>
                <Button type="danger" style={{ marginLeft: 8, display: this.props.showBatchDelete ? 'inline' : 'none' }} onClick={this.handleBatchDeletedClick}>批量删除 </Button>
                <Input.Search className='search' style={{ display: this.props.showSearch ? 'inline' : 'none' }}
                    placeholder={this.props.searchPlaceHolder}
                    onSearch={this.props.searchClick}
                    enterButton
                />
            </div>
        );
    }
}