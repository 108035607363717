import React from 'react'
import { message, Icon } from 'antd'

import deleteIcon from '../../images/ic_delete.jpg'
import FileUtils from '../../api/FileUtils';
import '../../styles/uploadbutton.css'

function beforeUpload(file) {
    const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
    if (!isJPG) {
        message.error('只支持jpg和png格式');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
        message.error('图片必须小于5M');
    }
    return isJPG && isLt2M;
}

export default class UploadFile extends React.Component {

    state = {
        loading: false
    }

    handleChange = (e) => {

        var file = e.target.files[0];
        if (file) {
            if (!beforeUpload(file)) return;
            e.target.value = null;
            this.props.onChange(null);
            this.setState({
                loading: true,
            });
            new FileUtils().getInstance().uploadFile(file, (data, error) => {
                if (error) {
                    message.error("图片上传失败");
                } else {
                    console.log("uplaoded->" + JSON.stringify(data));
                }
                const imageUrl = data.url;
                this.props.onChange(imageUrl);
                this.setState({
                    loading: false,
                })
            });
        }
    }

    deleteImage = (e) => {
        e.stopPropagation();
        console.log("delete image");
        this.props.onChange(null);
    }

    myUpload = (data) => {
        console.log("myUpload-> " + JSON.stringify(data));

    }


    render() {
        console.log("render-->" + this.props.value);
        if (!this.props.show) {
            return "--";
        }

        const imageUrl = this.props.value;
        return (
            <div>
                <span tabIndex="0" className="ant-upload upload" role="button">
                    <div className="matchparent">
                        {
                            imageUrl ?
                                <span>
                                    <img src={imageUrl} alt="菜品" className='image' />
                                    <img src={deleteIcon} alt="删除"
                                        style={{ width: "20px", height: "20px", position: "absolute", top: "-5px" }} onClick={this.deleteImage} />
                                </span>
                                : <Icon type={this.state.loading ? 'loading' : 'plus'} />
                        }
                    </div>
                    <input
                        className="input"
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={this.handleChange} />
                </span>
            </div>
        );
    }
}
