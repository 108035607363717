import React from 'react'
import {  Table, Modal, Pagination, message, Divider, Popconfirm } from 'antd'

import MainToolbar from './MainToolbar'
import Apis from '../../api'
import PageInfo from '../../pages/Paging'
import AddModal from './AddRankModal'
import Status from '../../api/Status';
import MyEmpty from '../common/MyEmpty'

export default class RankContainer extends React.Component {

    state = {
        isEditModal: false,
        addModalVisible: false,
        upLoading: false,
        loading: false,
        hallData: [],

    }

    selectedHallId = ""

    columns = [{
        title: '排名',
        dataIndex: '_num',
        render: (data, record, index) => {
            return <span style={{ fontWeight: "bold" }}>{index + 1}</span>
        }
    }, {
        title: '菜名',
        dataIndex: 'foodName',
    }, {
        title: '菜类',
        dataIndex: 'typeName',
        render: (data, record, index) => {
            return record.typeName;
        }
    }, {
        title: '图片',
        width: "120px",
        dataIndex: 'image',
        render: (data, record, index) => {
            if (record.showPicture) {
                if (record.picture) {
                    return <img src={record.picture} alt="" style={{ width: "100px", height: "60px" }} />
                } else {
                    return <span style={{ color: "red" }}>未设置</span>
                }
            } else {
                return '--'
            }
        }
    }, {
        title: '排序号',
        width: "80px",
        dataIndex: 'rank',
    },
    {
        title: '操作',
        width: "160px",
        dataIndex: 'operation',
        render: (text, record) => (
            record
                ? (
                    <span>
                        <a href="javascript:;" onClick={() => this.onEditClick(record)}>修改</a>
                        <Divider type="vertical" />
                        <Popconfirm title="确定删除该条数据?" okText="删除" okType="danger" cancelText="取消" onConfirm={(e) => {
                            e.preventDefault();
                            this.deleteItem(record);
                        }
                        }
                        >
                            <a href="javascript:;" style={{ color: "red" }}>删除</a>
                        </Popconfirm >
                    </span>
                ) : null
        ),
    }
    ];

    componentWillMount() {
        this.setState({
            ...this.state,
            loading: true
        });

        Apis.getHalls(PageInfo.getPage(0, 99))
            .then(data => {
                if (data.data == null || data.data.length < 1) {
                    message.error("请添加餐厅及菜品数据");
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    return;
                }

                this.selectedHallId = data.data[0].id;
                this.setState({
                    ...this.state,
                    hallData: data.data,

                });
                this.getRanks(this.selectedHallId);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    loading: false
                });
            })


    }

    currentPage = 0;
    getRanks = (hallId, pageIndex = 0) => {
        this.currentPage = pageIndex;

        this.setState({
            ...this.state,
            loading: true
        });
        Apis.getRanks(hallId, PageInfo.getPage(pageIndex))
            .then(data => {
                this.setState({
                    ...this.state,
                    loading: false,
                    data: data.data,
                    totalElements: data.totalElements
                });
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    loading: false,
                });
            })
    }



    onAddClick = () => {
        this.setState({
            ...this.state,
            isEditModal: false,
            addModalVisible: true,
            editData: null
        });
    }

    onEditClick = (item) => {
        this.setState({
            ...this.state,
            isEditModal: true,
            editData: item,
            addModalVisible: true
        });
    }

    addItem = (data) => {
        this.setState({
            ...this.state,
            upLoading: true
        });

        Apis.addRank(data)
            .then(data => {
                this.setState({
                    ...this.state,
                    addModalVisible: false,
                    upLoading: false,
                });
                this.getRanks(this.selectedHallId);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    upLoading: false,
                });
            });
    }

    editItem = (data) => {
        this.setState({
            ...this.state,
            upLoading: true
        });

        Apis.updateRank(data)
            .then(data => {
                this.setState({
                    ...this.state,
                    addModalVisible: false,
                    upLoading: false,
                });
                this.getRanks(this.selectedHallId, this.currentPage);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    upLoading: false,
                });
            });

    }

    deleteItem = (item) => {
        Apis.deleteRank(item)
            .then(data => {
                this.setState({
                    ...this.state,
                    upLoading: false,
                });
                this.getRanks(this.selectedHallId, this.currentPage);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    upLoading: false,
                });
            });
    }

    onAddModalCancelClick = () => {
        this.setState({
            ...this.state,
            upLoading: false,
            addModalVisible: false
        });

    }

    onPageChange = (page, pageSize) => {
        console.log("onPageChange page->" + page);
        //页码，需要减一
        this.currentPage = page - 1;
        this.getRanks(this.selectedHallId, this.currentPage);
    }

    hallChanged = (hall) => {
        this.selectedHallId = hall;
        this.getRanks(hall);
        console.log("hall->" + JSON.stringify(hall));
    }

    render() {
        // if (this.hallData == null || this.hallData.length < 1) return null;

        const { loading } = this.state;
        return (
            <div>
                <MainToolbar
                    showFilter={true}
                    selectData={this.state.hallData}
                    selectFilterChanged={this.hallChanged}
                    initSelectItem={this.selectedHallId}
                    showAllSelectItem={false}
                    showBatchDelete={false}
                    showSearch={false}
                    addClick={this.onAddClick} />
                <Table pagination={false}
                    locale={{ emptyText: <MyEmpty /> }}
                    rowKey={'id'}
                    columns={this.columns}
                    dataSource={this.state.data}
                    bordered
                    loading={loading} />
                <Pagination current={this.currentPage} onChange={this.onPageChange} total={this.state.totalElements} className="right" />
                {
                    this.state.addModalVisible ?
                        <AddModal isEditModal={this.state.isEditModal}
                            data={this.state.editData}
                            index={this.state.totalElements + 1}
                            hallData={this.state.hallData}
                            onOk={this.state.isEditModal ? this.editItem : this.addItem}
                            onCancel={this.onAddModalCancelClick}
                            visible={this.state.addModalVisible}
                            upLoading={this.state.upLoading} />
                        :
                        null
                }

                <Modal
                    title="确认"
                    okText="删除"
                    okType="danger"
                    cancelText="取消"
                    visible={this.state.deleteConfirmModalVisible}
                    onCancel={() => this.setState({
                        ...this.state,
                        deleteConfirmModalVisible: false
                    })}
                    onOk={this.handleConfirmDeleteOk}
                >
                    <p>确认删除已选中的记录吗？</p>
                </Modal>
            </div>
        )
    }

}