const DEBUG = false;
const URL_ROOT = DEBUG ? "http://localhost:80/api/" : "/api/";
// const URL_ROOT = DEBUG ? "https://62.234.74.243:5526/api/" : "/api/";
const KEY_ALL = -1;

const initLog = () => {
    console.log("changeLog====");
    const oldLog = console.log;
    const debug = DEBUG;
    console.log = (...optionalParams) => {
        if (debug) {
            oldLog(...optionalParams);
        }
    };
}

export default {
    URL_ROOT,
    KEY_ALL,
    DEBUG,
    initLog
}
