import React from 'react'
import { Modal, Button, Form, message, Steps } from 'antd';
import PropTypes from 'prop-types';

import '../../styles/addModal.css'
import FoodBasicInfoForm from './FoodBasicInfoForm'
import FoodDescriptionForm from './FoodDescriptionForm'
import FoodPriceForm from './FoodPriceForm'
import FoodTasteContainer from './FoodTasteContainer'
import Apis from '../../api'
import PageInfo from '../../pages/Paging'
import Status from '../../api/Status';

const Step = Steps.Step;

const steps = [{
    title: '基本信息',

    content: <FoodBasicInfoForm />,
}, {
    title: '菜品介绍',
    content: <FoodDescriptionForm />,
}, {
    title: '价格设置',
    content: <FoodBasicInfoForm />,
}, {
    title: '口味设置',
    content: <FoodBasicInfoForm />,
}];

export default class AddFoodModal extends React.Component {

    static propTypes = {
        handleOk: PropTypes.func.isRequired,
        handleCancel: PropTypes.func.isRequired,
        foodTypesData: PropTypes.array.isRequired
    }

    state = {
        current: 0,
        initData: this.props.initData
    }

    tasteTypes = [];

    handleSubmit = e => {
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    console.log("values->" + JSON.stringify(values));
                    if (this.state.initData != null) {
                        values = {
                            ...values,
                            id: this.state.initData.id
                        }
                    }

                    this.props.handleOk(values);
                }
            },
        );
    }

    handleCancel = e => {
        this.props.handleCancel();
    }

    handleHallChange = (data) => {

    }

    next() {
        if (this.state.current === 2) {
            if (!this.specifications.currentPrice) {
                if (!this.specifications.data || this.specifications.data.length === 0) {
                    message.error("请录入价格");
                    return;
                } if (this.specifications.data.findIndex(item => item.specId === "") >= 0) {
                    message.error("请完善价格数据");
                    return;
                } else {
                    console.log("spec data->" + JSON.stringify(this.specifications));
                }
            }

        }
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    console.log("current values->" + JSON.stringify(values));
                    const current = this.state.current + 1;
                    this.setState({
                        ...this.state,
                        current
                    });
                }
            }
        )

    }

    submit = () => {
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    values.currentPrice = this.specifications.currentPrice;
                    if (!values.currentPrice) {
                        values.specifications = this.specifications.data;
                    }
                    console.log("===submit ===\n" + JSON.stringify(values));
                    this.props.handleOk(values);
                }
            }
        )
    }
    prev() {
        const current = this.state.current - 1;
        this.setState({
            ...this.state,
            current
        });
    }

    specifications = { currentPrice: false, data: [] };
    priceDataChanged = (data) => {
        console.log("price data->" + JSON.stringify(data));
        this.specifications = data;
    }

    componentWillMount() {
        Apis.getTasteTypes(PageInfo.getPage(0, 100))
            .then(data => {
                if (data == null || data.data.length === 0) {
                    message.error("请首先添加菜品口味");
                    return;
                }

                this.tasteTypes = data.data;
                console.log("get taste->" + JSON.stringify(data));
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            });
    }

    onSimpleFoodInfoReturn = (value) => {
        const tmp = {
            ...this.state.initData,
            name: value.name,
            enName: value.enName,
            description: value.description,
            enDescription: value.enDescription,
            picture: value.picture
        };
        console.log("tmp -===>" + JSON.stringify(tmp));
        this.setState({
            ...this.state,
            initData: tmp
        });
    }

    // onStepClick = (e, d, s) => {
    //     var index = e.target.outerText;
    //     console.log("===step click===" + index);
    //     if (Number.isInteger(index) ||  parseInt(index)) {
    //         const current = index - 1;
    //         this.setState({
    //             ...this.state,
    //             current
    //         });
    //     }
    // }

    render() {
        const { current, initData } = this.state;
        const { visible, isEditModal, foodTypesData } = this.props;
        console.log("initdata->" + JSON.stringify(initData));
        if (!visible) return null;
        return (
            <Modal visible={true}
                title={isEditModal ? "修改菜品" : "添加菜品"}
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                width="750px"
                footer={
                    <div className="steps-action">
                        {
                            current > 0
                            && (
                                <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                                    上一步
                                </Button>
                            )
                        }
                        {
                            current < steps.length - 1
                            && <Button type="primary" htmlType="submit" key="submit" onClick={() => this.next()}>下一步</Button>
                        }
                        {
                            current === steps.length - 1
                            && <Button type="primary" htmlType="submit" onClick={() => {
                                // console.log("xx =>" + JSON.stringify(data));
                                this.submit();
                            }
                            }>提交</Button>
                        }

                    </div>
                }
            >

                <div>
                    <Steps size="small" current={current}>
                        {steps.map(item => <Step key={item.title} title={item.title}
                            // onClick={this.onStepClick}
                            />)}
                    </Steps>
                    <Form style={{ marginTop: "10px" }}
                        layout={"horizontal"}
                    >
                        {
                            current >= 0 ?
                                <FoodBasicInfoForm
                                    onSimpleFoodInfoReturn={this.onSimpleFoodInfoReturn}
                                    initData={initData}
                                    visible={current === 0}
                                    form={this.props.form}
                                    index={this.props.index}
                                    hallData={this.props.hallData}
                                    foodTypesData={foodTypesData} /> : null
                        }
                        {
                            current >= 1 ?
                                <FoodDescriptionForm
                                    initData={initData}
                                    visible={current === 1}
                                    form={this.props.form} /> : null
                        }
                        {
                            current >= 2 ?
                                <FoodPriceForm
                                    priceDataChanged={this.priceDataChanged}
                                    visible={current === 2}
                                    form={this.props.form}
                                    initData={initData ? initData.specifications : []}
                                    currentPrice={initData ? initData.currentPrice : false} /> : null
                        }

                        {
                            current >= 3 ?
                                <FoodTasteContainer
                                    initData={initData ? initData.tastes : []}
                                    visible={current === 3}
                                    form={this.props.form}
                                    tasteTypes={this.tasteTypes} /> : null
                        }
                    </Form>
                </div>

            </Modal>
        );
    }
}

AddFoodModal = Form.create()(AddFoodModal);
