import React from 'react'
import { Form, Input } from 'antd'

const { TextArea } = Input;
export default class FoodDescriptionForm extends React.Component {

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };
        const initData = this.props.initData
        return (
            <div
                style={{ display: this.props.visible ? 'inline' : 'none' }}
            >
                <Form.Item label="描述:" {...formItemLayout}>
                    {getFieldDecorator('description', {
                        initialValue: initData != null ? initData.description : "",
                    })(
                        <TextArea placeholder="输入菜品中文描述" rows={6} />
                    )}

                </Form.Item>
                <Form.Item label="英文描述:" {...formItemLayout}>
                    {getFieldDecorator('enDescription', {
                        initialValue: initData != null ? initData.enDescription : "",
                    })(
                        <TextArea placeholder="输入菜品英文描述" rows={6} />
                    )}
                </Form.Item>
            </div>
        );
    }
}


