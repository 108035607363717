import React from 'react'

import { Table, message, Popconfirm, Divider, Modal, Pagination } from 'antd';

import MainToolbar from '../../components/MainToolbar'
import Apis from '../../api/'
import PageInfo from '../../pages/Paging'
import AddModal from '../food/AddFoodTypeModal'
import Status from '../../api/Status';

import '../../styles/pagination.css'
import StringUtils from '../../util/StringUtils'
import Config from '../../api/config'
import MyEmpty from '../common/MyEmpty'

export default class FoodTypeListContainer extends React.Component {
    state = {
        addModalVisible: false, //显示新增页面
        isEditModal: false, //是否是修改item数据
        editData: null, //修改的item数据
        upLoading: false,   //添加数据时是否正在上传数据标识

        deleteConfirmModalVisible: false,   //是否弹出删除确认框

        loading: false, //图标数据加载状态
        selectedRowKeys: [], // 已选中表单项id

        currentPage: 1, //当前页面页码
        totalElements: 0,   //总数据条数（table数据条数）

    };
    selectedRows = [];
    data = [];  //表格数据
    hallData = [];  //餐厅数据
    filters = {};

    columns = [{
        title: '名称',
        dataIndex: 'name',
    }, {
        title: '英文名称',
        dataIndex: 'enName',
    }, {
        title: '所属餐厅',
        dataIndex: 'hall',
        render: (data, record, index) => {
            const hall = this.getHallById(record.hallId);
            return hall == null ? "未知" : hall.name;
        }
    }, {
        title: '是否有图',
        width: "100px",
        dataIndex: 'showPicture',
        render: (text, record, index) => {
            return text == true ? "是" : <label style={{ color: "red" }}>否</label>
        }
    }, {
        title: '排序号',
        width: "80px",
        dataIndex: 'order',
    },
    {
        title: '操作',
        width: "150px",
        dataIndex: 'operation',
        render: (text, record) => (
            this.data.length >= 1
                ? (
                    <span>
                        <a href="javascript:;" onClick={() => this.onEditItemClick(record)}>修改</a>
                        <Divider type="vertical" />
                        <Popconfirm title="确定删除该条数据?" okText="删除" okType="danger" cancelText="取消" onConfirm={() =>
                            this.deleteItem(record)
                        }
                        >
                            <a href="javascript:;" style={{ color: "red" }}>删除</a>
                        </Popconfirm >
                    </span>
                ) : null
        ),
    }
    ];

    getHallById = (id) => {
        console.log("hallData->" + JSON.stringify(this.hallData) + "    id->" + id);
        const halls = this.hallData;
        if (halls != null) {
            var tmp;
            halls.forEach(hall => {
                if (hall.id === id) {
                    tmp = hall;
                }
            });
            return tmp;
        }
    }

    start = () => {
        // this.filters = {name: ""};
        this.setState({
            ...this.state,
            addModalVisible: false,
            loading: true
        });

        Apis.getHalls(PageInfo.getPage(0, 100), this.filters)
            .then(data => {
                if (data == null || data.data.length === 0) {
                    message.error("请先添加餐厅");
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    return;
                }
                this.hallData = data.data;
                this.loadPage(0);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })

    }

    // handleHallData = (data) => {
    //     var result = [];
    //     data.forEach(element => {
    //         result.push({ id: element.id, name: element.name });
    //     });

    //     return result;
    // }


    loadPage = (pageIndex) => {
        console.log("load page. filters ->" + JSON.stringify(this.filters));
        this.setState({
            ...this.state,
            loading: true
        });
        Apis.getFoodTypes(PageInfo.getPage(pageIndex), this.filters)
            .then(data => {
                this.data = data.data;
                this.setState({
                    loading: false,
                    currentPage: pageIndex + 1,
                    totalElements: data.totalElements
                });
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRows changed: ', selectedRows);
        this.selectedRows = selectedRows;
        this.setState({
            ...this.state,
            selectedRowKeys
        });
    }

    onAddClick = (e) => {
        this.setState({
            ...this.state,
            addModalVisible: true,
            isEditModal: false,
            editData: null
        });
    }

    onBatchDeleteClick = (e) => {
        const selectedData = this.selectedRows;
        if (selectedData == null || selectedData.length < 1) {
            message.info("请选择要删除的行");
        } else {
            Modal.confirm({
                title: '您确定要删除已选中的数据吗？',
                okText: "删除",
                cancelText: "取消",
                onOk: () => {
                    console.log("xxx1" + this.selectedData);
                    console.log("xxx2" + this.selectedRows);
                    this.deleteStoreInBatch(selectedData);
                }
            });
        }
    }

    onSearchClick = (value, event) => {
        if (value === this.filters.name) return;
        console.log("do search->" + value);
        const data = StringUtils.trim(value);
        this.filters = {
            ...this.filters,
            name: data
        }

        this.loadPage(0);
    }

    onEditItemClick = (data) => {
        this.setState({
            ...this.state,
            addModalVisible: true,
            isEditModal: true,
            editData: data
        });
    }

    onPageChange = (page, pageSize) => {
        console.log("onPageChange page->" + page);
        //页码，需要减一
        this.loadPage(page - 1);
    }

    addFoodType = (data) => {
        Apis.addFoodType(data)
            .then(data => {
                this.start();
            })
            .catch(error => {
                console.log(error);
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    addModalVisible: false
                });
            })
    }

    editFoodType = (data) => {
        Apis.updateFoodType(data)
            .then(data => {
                this.start();
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    addModalVisible: false
                });
            })
    }

    deleteItem = (record) => {
        Apis.deleteFoodType(record)
            .then(data => {
                this.start();
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    }

    deleteStoreInBatch = (records) => {
        Apis.deleteFoodTypes(records)
            .then(data => {
                this.start();
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
            })
    }

    onAddModalCancelClick = e => {
        this.setState({
            ...this.state,
            addModalVisible: false,
            isEditModal: false,
            editData: null
        })
    }

    selectFilterChanged = (key) => {

        if (key == Config.KEY_ALL) {
            key = null;
        }
        this.filters = {
            ...this.filters,
            hallId: key
        }
        this.loadPage(0);
    }

    componentDidMount() {
        console.log("componentDidMount")
        this.start();
    }

    render() {
        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <MainToolbar
                    showBatchDelete={false}
                    selectData={this.hallData}
                    selectFilterChanged={this.selectFilterChanged}
                    selectTitle={"选择餐厅"}
                    batchDeleteClick={this.onBatchDeleteClick}
                    addClick={this.onAddClick}
                    searchClick={this.onSearchClick} />
                <Table
                locale={{ emptyText: <MyEmpty /> }}
                 pagination={false} rowSelection={rowSelection} rowKey={'id'} columns={this.columns} dataSource={this.data} bordered loading={loading} />
                <Pagination current={this.state.currentPage} onChange={this.onPageChange} total={this.state.totalElements} className="right" />
                <AddModal isEditModal={this.state.isEditModal}
                    initData={this.state.editData}
                    index={this.state.totalElements + 1}
                    hallData={this.hallData}
                    handleOk={this.state.isEditModal ? this.editFoodType : this.addFoodType}
                    handleCancel={this.onAddModalCancelClick} visible={this.state.addModalVisible}
                    upLoading={this.state.upLoading} />

                <Modal
                    title="确认"
                    okText="删除"
                    okType="danger"
                    cancelText="取消"
                    visible={this.state.deleteConfirmModalVisible}
                    onCancel={() => this.setState({
                        ...this.state,
                        deleteConfirmModalVisible: false
                    })}
                    onOk={this.handleConfirmDeleteOk}
                >
                    <p>确认删除已选中的记录吗？</p>
                </Modal>
            </div>

        );
    }
}
