import React from 'react'
import { Modal, Button, Input, Form, message } from 'antd';
import PropTypes from 'prop-types';

import '../../styles/addModal.css'


export default class AddStoreModal extends React.Component {

    static propTypes = {
        handleOk: PropTypes.func.isRequired,
        handleCancel: PropTypes.func.isRequired
    }

    handleSubmit = e => {
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    if (this.props.initData != null) {
                        if (this.props.initData.name === values.name &&
                            this.props.initData.enName === values.enName) {
                            message.warn("没有修改任何内容", 0.5);
                            return;
                        }
                        values = {
                            ...values,
                            id: this.props.initData.id
                        }
                    }
                    
                    this.props.handleOk(values);
                }
            },
        );
    }

    handleCancel = e => {
        this.props.handleCancel();
    }

    render() {
        const { visible, upLoading } = this.props;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };
        const initData = this.props.initData;
        if (!visible) return null;

        return (
            <Modal visible={true}
                title={this.props.isEditModal ? "修改餐厅" : "添加餐厅"}

                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="cancel" onClick={this.handleCancel}>取消</Button>,
                    <Button key="submit" type="primary" loading={upLoading} onClick={this.handleSubmit}>提交</Button>
                ]}
            >
                <Form
                    layout={"horizontal"}
                >
                    <Form.Item label="名称:" {...formItemLayout}>
                        {getFieldDecorator('name', {
                            initialValue: initData != null ? initData.name : "",
                            rules: [{
                                required: true,
                                message: '请输入餐厅名称',
                            },
                            {
                                min: 1, max: 10,
                                message: '请输入1-10个字符长度'
                            }
                            ],
                        })(
                            <Input placeholder="餐厅名称" />
                        )}

                    </Form.Item>
                    <Form.Item label="英文名称:" {...formItemLayout}>
                        {getFieldDecorator('enName', {
                            initialValue: initData != null ? initData.enName : "",
                            rules: [{
                                required: true,
                                message: '请输入餐厅英文名称',
                            },
                            {
                                min: 1, max: 30,
                                message: '请输入1-30个字符长度'
                            },
                            ],
                        })(
                            <Input placeholder="餐厅英文名称" />
                        )}

                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

AddStoreModal = Form.create()(AddStoreModal);