import React from 'react'
import { Table, Modal, Pagination, message, Divider, Popconfirm } from 'antd'
import moment from 'moment';

import MainToolbar from '../../components/MainToolbar'
import Apis from '../../api'
import PageInfo from '../../pages/Paging'
import AddModal from './AddModal'
import Status from '../../api/Status';
import MyEmpty from '../common/MyEmpty'

const format = 'YYYY/MM/DD';
export default class DeviceContailer extends React.Component {

    state = {
        isEditModal: false,
        addModalVisible: false,
        upLoading: false,
        loading: false,
        hallData: [],

    }

    columns = [
        {
            title: "序号",
            dataIndex: "_index",
            render: (data, record, index) => {
                return index + 1;
            }
        },

        {
            title: "别名",
            dataIndex: "alias",
        },
        {
            title: "序列号",
            dataIndex: "serialNum",
        },
        {
            title: "激活日期",
            dataIndex: "startTime",
            render: (data, record, index) => {
                if (record.startTime) {
                    return moment(record.startTime).format(format);
                } else {
                    return '--';
                }
            }
        },
        {
            title: "过期日期",
            dataIndex: "expireTime",
            render: (data, record, index) => {
                if (record.expireTime) {
                    return moment(record.expireTime).format(format);
                } else {
                    return '--';
                }
            }
        },
        {
            title: "状态",
            render: (data, record, index) => {
                if (record.expireTime) {
                    if (this.isBeforeNow(record.expireTime)) {
                        return <span style={{ color: 'red' }}>已过期</span>;
                    } else {
                        if (this.isBeforeNow(record.startTime)) {
                            return <span style={{ color: 'green' }}>有效</span>;
                        } else {
                            return <span style={{ color: "blue" }}>未到期</span>;

                        }
                    }
                } else {
                    return <span style={{ color: "red" }}>未激活</span>;
                }
            }
        }, {
            title: '操作',
            width: "160px",
            dataIndex: 'operation',
            render: (text, record) => (
                record
                    ? (
                        <span>
                            {/* <a href="javascript:;" onClick={() => this.onEditClick(record)}>修改</a> */}
                            {/* <Divider type="vertical" /> */}
                            <Popconfirm title="确定删除该条数据?" okText="删除" okType="danger" cancelText="取消" onConfirm={(e) => {
                                e.preventDefault();
                                this.deleteItem(record);
                            }
                            }
                            >
                                <a href="javascript:;" style={{ color: "red" }}>删除</a>
                            </Popconfirm >
                        </span>
                    ) : null
            ),
        }
    ]
    onAddClick = () => {
        this.setState({
            ...this.state,
            isEditModal: false,
            addModalVisible: true,
            initData: null
        });
    }

    isBeforeNow = (time) => {
        return moment(new Date(time)).isBefore(moment());
    }

    onEditClick = (item) => {
        this.setState({
            ...this.state,
            isEditModal: true,
            initData: item,
            addModalVisible: true
        });
    }

    addItem = (data) => {
        this.setState({
            ...this.state,
            upLoading: true
        });

        Apis.addDevice(data)
            .then(data => {
                this.setState({
                    ...this.state,
                    addModalVisible: false,
                    upLoading: false,
                });
                this.loadPage(0);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    upLoading: false,
                });
            });
    }

    editItem = (data) => {
        this.setState({
            ...this.state,
            upLoading: true
        });

        Apis.updateDevice(data)
            .then(data => {
                this.setState({
                    ...this.state,
                    addModalVisible: false,
                    upLoading: false,
                });
                this.loadPage(this.state.currentPage - 1);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    upLoading: false,
                });
            });

    }

    deleteItem = (item) => {
        Apis.deleteDevice(item)
            .then(data => {
                this.setState({
                    ...this.state,
                    upLoading: false,
                });
                this.loadPage(this.state.currentPage - 1);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    upLoading: false,
                });
            });
    }

    onAddModalCancelClick = () => {
        this.setState({
            ...this.state,
            upLoading: false,
            addModalVisible: false
        });

    }

    onPageChange = (pageIndex) => {
        this.loadPage(pageIndex - 1);
    }

    loadPage = (pageIndex) => {
        this.setState({
            ...this.state,
            loading: true
        });

        Apis.getDevices(PageInfo.getPage(pageIndex))
            .then(data => {
                this.setState({
                    ...this.state,
                    loading: false,
                    data: data.data,
                    totalElements: data.totalElements,
                    currentPage: pageIndex + 1
                })
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    loading: false
                })
            })
    }

    componentDidMount() {
        this.loadPage(0);
    }


    render() {

        const { loading } = this.state;
        return (
            <div>
                <MainToolbar
                    showFilter={false}
                    showBatchDelete={false}
                    showSearch={false}
                    addClick={this.onAddClick} />
                <Table pagination={false}
                locale={{ emptyText: <MyEmpty /> }}
                    rowKey={'_index'}
                    columns={this.columns}
                    dataSource={this.state.data}
                    bordered
                    loading={loading} />
                <Pagination current={this.state.currentPage} onChange={this.onPageChange} total={this.state.totalElements} className="right" />
                {
                    this.state.addModalVisible ?
                        <AddModal isEditModal={this.state.isEditModal}
                            initData={this.state.initData}
                            onOk={this.state.isEditModal ? this.editItem : this.addItem}
                            onCancel={this.onAddModalCancelClick}
                            visible={this.state.addModalVisible}
                            upLoading={this.state.upLoading} />
                        :
                        null
                }

                <Modal
                    title="确认"
                    okText="删除"
                    okType="danger"
                    cancelText="取消"
                    visible={this.state.deleteConfirmModalVisible}
                    onCancel={() => this.setState({
                        ...this.state,
                        deleteConfirmModalVisible: false
                    })}
                    onOk={this.handleConfirmDeleteOk}
                >
                    <p>确认删除已选中的记录吗？</p>
                </Modal>
            </div>
        )
    }
}