import React from 'react'
import { Checkbox, Row, Col, Form, message } from 'antd'
import PropTypes from 'prop-types';

import Apis from '../../api'
import PageInfo from '../../pages/Paging'
import Status from '../../api/Status';

export default class FoodTasteContainer extends React.Component {

    static propTypes = {
        tasteTypes: PropTypes.array.isRequired
    }

    static defaultProps = {
        data: []
    }

    state = {
        data: this.props.tasteTypes
    }

    componentWillMount() {
        if (this.state.data == null || this.state.data.length === 0) {
            Apis.getTasteTypes(PageInfo.getPage(0, 100))
                .then(data => {
                    if (data == null || data.data.length === 0) {
                        message.error("请首先添加菜品口味");
                        return;
                    }

                    var tasteTypes = data.data;
                    this.setState({
                        data: tasteTypes
                    })
                })
                .catch(error => {
                    message.error(Status.getMessage(error.code));
                });
        }
    }

    onChange = (data) => {

    };

    getTasteName = (id) => {
        var name = "未知"
        this.props.tasteTypes.forEach(type => {
            if (type.id === id) name = type.name;
        });

        return name;
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div style={{ display: this.props.visible ? 'inline' : 'none' }}>
                <Form.Item label="勾选菜品口味: " >
                    {getFieldDecorator('tastes', {
                        initialValue: this.props.initData ? this.props.initData.map(item => {
                            return item.tasteId;
                        }) : [],
                        // rules: [{
                        //     required: true,
                        //     message: '请选择菜品口味',
                        // }
                        // ],
                    })(
                        <Checkbox.Group style={{ width: '100%' }}  >
                            <Row>
                                {
                                    this.state.data.map(item => {
                                        return <Col key={item.id} span={8}><Checkbox value={item.id}>{item.name}</Checkbox></Col>
                                    })
                                }
                            </Row>
                        </Checkbox.Group>
                    )}

                </Form.Item>
            </div >
        )
    };

}