import React from 'react'
import { Modal, Button, Form, message } from 'antd';
import PropTypes from 'prop-types';

import '../../styles/addModal.css'
import FoodPriceForm from './FoodPriceForm'
import Apis from '../../api'


export default class EditFoodPriceModal extends React.Component {

    static propTypes = {
        handleOk: PropTypes.func.isRequired,
        handleCancel: PropTypes.func.isRequired,
    }

    state = {
        initData: this.props.initData
    }


    handleSubmit = e => {
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    console.log("values->" + JSON.stringify(values));
                    if (this.state.initData != null) {
                        values = {
                            ...values,
                            id: this.state.initData.id
                        }
                    }

                    this.props.handleOk(values);
                }
            },
        );
    }

    handleCancel = e => {
        this.props.handleCancel();
    }


    submit = () => {
        var values = this.state.initData;
        values.currentPrice = this.specifications.currentPrice;
        if (!values.currentPrice) {
            values.specifications = this.specifications.data;
        } else {
            values.specifications = [];
        }
        //格式化口味
        if (values.tastes ) {
            var tmpTastes = [];
            values.tastes.forEach(t => {
                tmpTastes.push(t.tasteId);
            });

            values.tastes = tmpTastes;
        }
        this.props.handleOk(values);

    }

    priceDataChanged = (data) => {
        console.log("price data->" + JSON.stringify(data));
        this.specifications = data;
    }

    render() {
        const { current, initData } = this.state;
        const { visible, isEditModal, foodTypesData } = this.props;
        console.log("initdata->" + JSON.stringify(initData));
        if (!visible) return null;
        return (
            <Modal visible={true}
                title={"快速修改价格"}
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                width="750px"
                footer={[
                    <Button key="cancel" onClick={this.handleCancel}>取消</Button>,
                    <Button key="submit" type="primary" onClick={() => {
                        this.submit();
                    }}>提交</Button>
                ]}
            >

                <div>
                    <Form style={{ marginTop: "10px" }}
                        layout={"horizontal"}
                    >
                        <FoodPriceForm
                            priceDataChanged={this.priceDataChanged}
                            visible={true}
                            form={this.props.form}
                            initData={initData ? initData.specifications : []}
                            currentPrice={initData ? initData.currentPrice : false} />
                    </Form>
                </div>

            </Modal>
        );
    }
}

EditFoodPriceModal = Form.create()(EditFoodPriceModal);
