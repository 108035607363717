import React from 'react'
import { Route, Link, BrowserRouter, Switch } from 'react-router-dom'
import { Layout, Menu, Icon } from 'antd';

import MainHeader from '../../components/MainHeader'
import XBreadcrumb from '../../components/XBreadcrumb';
import StoreListContainer from '../../containers/store/StoreListContainer'
import FoodTypeListContainer from '../../containers/food/FoodTypeListContainer'
import Storage from '../../util/Storage'
import TasteTypesContainer from '../taste/TasteTypesContainer';
import SpecificationsContainer from '../specification/SpecificationsContainer'
import FoodsContainer from '../food/FoodsContainer';
import RankContainer from '../rank/RankContainer'
import IntroductionContainer from '../introduction/IntroductionContainer'
import DeviceContailer from '../device/DeviceContainer';
import Copyright from '../../components/Copyright';
import config from '../../api/config';

const { Header, Sider, Content, Footer } = Layout;
const SubMenu = Menu.SubMenu;
export default class MainContainer extends React.Component {


    // token = localStorage.getItem("auth");

    constructor(props) {
        super();
        this.token = Storage.getAuth();
        this.user = Storage.getUser();
        this.state = {
            collapsed: false,
            breadcrumbs: ['餐厅管理']
        };
    }

    toggle = () => {
        
        this.setState({
            ...this.state,
            collapsed: !this.state.collapsed,
        });
    }

    componentWillMount() {
        if (!this.user || !this.token) {
            this.props.history.replace('/login')
        }
    }

    onSelected = e => {
        console.log('onselected->' + e);
        this.setState({
            ...this.state,
            breadcrumbs: this.getBreadcrumb(e)
        });
    }

    getBreadcrumb = (e) => {
        return e.item.props.parent ? [e.item.props.parent, e.item.props.label] : [e.item.props.label];
    }


    render() {
        return (
            <BrowserRouter>
                <div className='h100'>
                    <MainHeader history={this.props.history} ></MainHeader>
                    <Layout className='content' >
                        <Sider
                            trigger={null}
                            collapsible
                            collapsed={this.state.collapsed}
                            className='mysider'
                        >
                            <div className="logo" />
                            <Menu className='mymenu' theme="light" mode="inline" onSelect={this.onSelected} defaultSelectedKeys={['store']}>
                                <Menu.Item key="store" label="餐厅管理">
                                    <Link to='/' style={{}}>
                                        <Icon type="shop" theme='filled' />
                                        <span>餐厅管理</span>
                                    </Link>
                                </Menu.Item>
                                <SubMenu
                                    key="food"
                                    title={<span><Icon type="book" theme='filled' /><span>菜品管理</span></span>}>
                                    <Menu.Item key="1" parent="菜品管理" label="菜品列表"><Link to='/foods'>菜品列表</Link></Menu.Item>
                                    {/* <Menu.Item key="2" parent="菜品管理" label="套餐管理"><Link to='/food/setmeal'>套餐管理</Link></Menu.Item> */}
                                    <Menu.Item key="3" parent="菜品管理" label="菜品类别"><Link to='/food/type'>菜品类别</Link></Menu.Item>
                                    <Menu.Item key="4" parent="菜品管理" label="规格类型"><Link to='/specs'>规格类型</Link></Menu.Item>
                                    <Menu.Item key="5" parent="菜品管理" label="口味类型"><Link to='/tastes'>口味类型</Link></Menu.Item>
                                </SubMenu>
                                <Menu.Item key="rank" label="排行榜">
                                    <Link to='/rank' style={{}}>
                                        <Icon type="fund" theme='filled' />
                                        <span>排行榜</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="introduction" label="酒店介绍">
                                    <Link to='/intro' style={{}}>
                                        <Icon type="file-text" theme='filled' />
                                        <span>酒店介绍</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="device" label="设备管理">
                                    <Link to='/device' style={{}}>
                                        <Icon type="tablet" theme='filled' />
                                        <span>设备管理</span>
                                    </Link>
                                </Menu.Item>
                                {/* <Menu.Item key="profile" label="个人信息">
                                    <Icon type="idcard" theme='filled' />
                                    <span>个人信息</span>
                                </Menu.Item> */}
                            </Menu>
                        </Sider>
                        <Layout className='right-content'>
                            <Header className="header-icon" style={{ background: '#fff', padding: '0  0  0 25px', height: '50px' }}>
                                <Icon
                                    className="trigger header-step"
                                    type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                    onClick={this.toggle}
                                />
                                <XBreadcrumb data={this.state.breadcrumbs} />
                            </Header>
                            <Content style={{
                                margin: '14px 10px', padding: '14px 14px 0px 14px', background: '#fff', minHeight: 280,
                            }}>
                                <Switch>
                                    <Route exact path='/' component={StoreListContainer}></Route>
                                    <Route path='/food/type' component={FoodTypeListContainer}></Route>
                                    <Route path='/tastes' component={TasteTypesContainer}></Route>
                                    <Route path='/specs' component={SpecificationsContainer}></Route>
                                    <Route path='/foods' component={FoodsContainer}></Route>
                                    <Route path='/rank' component={RankContainer}></Route>
                                    <Route path='/intro' component={IntroductionContainer}></Route>
                                    <Route path='/device' component={DeviceContailer}></Route>
                                </Switch>
                            </Content>
                            <Footer className="footer">
                                <Copyright />
                            </Footer>
                        </Layout>
                    </Layout>
                </div>
            </BrowserRouter >
        );
    }
}