import React from 'react'

import {
    Form, Icon, Input, Button
} from 'antd'
import '../containers/login/login.css'
import title from '../images/menu_title.png'
import phoneIcon from '../img/phone-icon.png'
import user from '../img/user.png'
import userKey from '../img/key.png'
const FormItem = Form.Item;

export default class LoginForm extends React.Component {
    userName = '' //Storage.getUserName();
    // userName = localStorage.getItem('userName');

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onLogin(values.userName, values.psw
                )
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
          <div className="left-content">
              <div className="up-form">
                  <div className="up-title">
                      <div className="menu-title">
                          <img src={title}/>
                      </div>
                      <div className="compony">河北云石科技有限公司</div>
                  </div>
                  <div className="loginContent">
                      <label style={{ display: this.props.isError ? 'visible' : 'invisible', textAlign: 'left', color: 'red' }}>{this.props.msg}</label>
                      <Form onSubmit={this.handleSubmit} className="login-form">
                          <FormItem className="formItem">
                              {getFieldDecorator('userName', {
                                  rules: [{ required: true, message: '请输入用户名' }],
                              })(
                                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} defaultValue=".userName "/>} placeholder="用户名" />
                                /*<Input prefix={user} placeholder="用户名" />*/
                              )}
                          </FormItem>
                          <FormItem>
                              {getFieldDecorator('psw', {
                                  rules: [{ required: true, message: '请输入密码' }],
                              })(
                                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password"
                                       placeholder="密码" />
                                /*<Input prefix={userKey} type="password"
                                       placeholder="密码" />*/
                              )}
                          </FormItem>
                          <FormItem className="down-btn">
                              <Button type="primary" htmlType="submit" className="login-form-button submit">
                                  登录
                              </Button>
                              <br />
                              <label style={{ float: "right" }}>
                                  或 <a href="/register">立即注册</a></label>
                          </FormItem>
                      </Form>
                  </div >
              </div>
              <div className="down-form">
                  <img src={phoneIcon}/>
                  15210361969/15010326051</div>
          </div>
        );
    }
}

LoginForm = Form.create()(LoginForm);
