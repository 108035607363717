import React from 'react'
import { Modal, message, Input, Form, Select, Button } from 'antd'
import PropTypes from 'prop-types';

import UploadFile from '../food/UploadPicture'

const Option = Select.Option;

export default class AddIntroductionModal extends React.Component {

    static propTypes = {
        // hallsData: PropTypes.array.required,
        // typesData: PropTypes.array.required,
    }

    commit = () => {
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    values = {
                        ...values,
                        "id": this.props.initData ? this.props.initData.id : null
                    }
                    this.props.onOk(values);
                }
            }
        )
    }

    render() {
        const { visible, initData, upLoading, onCancel } = this.props;
        if (!visible) return null;

        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };


        return (
            <Modal visible={true}
                okType="primary"
                title={initData == null ? "添加酒店介绍" : "修改酒店介绍"}
                onOk={this.commit}
                onCancel={onCancel}
                footer={[
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="submit" type="primary" loading={upLoading} onClick={this.commit}>提交</Button>
                ]}>

                <Form
                    layout={"horizontal"}


                >

                    {/* <Form.Item label="所属餐厅:" {...formItemLayout}>
                        {getFieldDecorator('hallId', {
                            initialValue: initData != null ? initData.hallId : "",
                            rules: [{
                                required: true,
                                message: '请选择所属餐厅',
                            }
                            ],

                        })(
                            <Select onChange={this.handleHallChange}
                            >
                                {
                                    this.props.hallData.map(element =>
                                        <Option key={element.id} value={element.id}>{element.name}</Option>
                                    )
                                }
                            </Select>
                        )}

                    </Form.Item> */}
                    <Form.Item label="所属类别:" {...formItemLayout}>
                        {getFieldDecorator('typeId', {
                            initialValue: initData != null ? initData.typeId : "",
                            rules: [{
                                required: true,
                                message: '请选择所属类别',
                            }
                            ],

                        })(
                            <Select
                                onChange={this.handleTypeChange}
                            >
                                {
                                    this.props.typeData.map(element =>
                                        <Option key={element.id} value={element.id}>{element.name}</Option>
                                    )
                                }
                            </Select>
                        )}

                    </Form.Item>
                    <Form.Item label="图片:" {...formItemLayout}>
                        {getFieldDecorator('picture', {
                            initialValue: initData != null ? initData.picture : "",
                            rules: [{
                                // required: true,
                                // message: '请上传图片',
                            }
                            ],
                        })(
                            < UploadFile show={true} />
                        )}

                    </Form.Item>

                    <Form.Item label="排序号:" {...formItemLayout}>
                        {getFieldDecorator('order', {
                            initialValue: initData == null ? 1 : initData.order,
                            rules: [{
                                required: true,
                                message: '请输入排序号',
                            }
                            ],
                        })(
                            <div >
                                <Input type="number" defaultValue={initData == null ? 1 : initData.order} min={1} style={{ width: "100px" }} />
                                <label style={{ marginLeft: "20px", color: "gray" }}>（值越大，排序越靠前）</label></div>
                        )}
                    </Form.Item>

                </Form>
            </Modal>
        )
    }
}

AddIntroductionModal = Form.create()(AddIntroductionModal);
